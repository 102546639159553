import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import {toast} from 'react-toastify';
import moment from "moment";
import { FormikState } from "formik";
import React from "react";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IUniversityCollege} from "../../../blocks/social-media-account-registration/src/types";
const cityList =require('../../social-media-account-registration/assets/cities.json');
const countryCodes =require('../../social-media-account-registration/assets/countryCodes.json');
import { parsePhoneNumber, CountryCode, isValidPhoneNumber } from 'libphonenumber-js'

const calculateUserAge = (dateOfBirth:string) => {
  const now = moment();
  const birthDate = moment(dateOfBirth);
  const years = now.diff(birthDate, "years");
  return years>=18;
};

export const PhoneValidation = (mobile_number: string, id: CountryCode): boolean => {
  try {
    const validateparsedPhoneNumber = parsePhoneNumber(mobile_number, id);
    return !!validateparsedPhoneNumber && validateparsedPhoneNumber.isValid();
  } catch (error) {
    console.log(error,"error")
    return false
  }
}

export const BasicDetailsValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name can't be blank"),
  lastName: Yup.string().required("Last Name can't be blank"),
  gender: Yup.string().required("Gender can't be blank"),
  mobileNumber: Yup.string()
  .required("Please enter mobile number")
  .test('mobile_number', 'Invalid Phone number', function(value) {
    if (!value || value.length === 0) {
      return this.createError({ message: 'Please enter your mobile number' });
    }
    let phonenumber = value.split("");
    phonenumber.splice(0, this.parent.dialCode.length);
    if (!phonenumber || phonenumber.length === 0) {
      return this.createError({ message: 'Please enter your mobile number' });
    }
    return PhoneValidation(phonenumber.join(""), this.parent.countryCode)  }),
  address1: Yup.string().required("Address1 can't be blank")
  .min(10,"Address line 2 should have minimum 10 and maximum 150 characters")
  .max(150,"Address line 2 should have minimum 10 and maximum 150 characters"),
  address2: Yup.string().required("Address2 can't be blank")
  .min(10,"Address line 2 should have minimum 10 and maximum 150 characters")
  .max(150,"Address line 2 should have minimum 10 and maximum 150 characters"),
  country: Yup.string().required("Please select country"),
  city: Yup.string().required("Please enter or select city"),
  state: Yup.string().required("Please enter or select state"),
  postalCode: Yup.string().required("Postal code can't be blank").min(3,"Postal code should have minimum 3 digits."),
  birthdate: Yup.string().required("Please select birthdate")
  .test("birthday","DOB should not be later than today",function(val){
    return new Date() >= new Date(val as string)
  }).test("birthday", "Age must be over 18 years", function(val) {
    return calculateUserAge(val as string);
}),
  timeZone: Yup.string().required("Please select time zone"),
  currency: Yup.string().required("Please select currency"),
});

export const HoursValidation = Yup.object().shape({
  hourlyChargesValue: Yup.string().required("Please enter hourly rates"),
});


export const AddWorkExpValidation = Yup.object().shape({
  jobTitle: Yup.string().required("Please enter job title"),
  employerName: Yup.string().required("Please enter employer name"),
  country: Yup.string().required("Please select country"),
  state: Yup.string().required("Please enter or select state"),
  city: Yup.string().required("Please enter or select city"),
  jobDecritption: Yup.string().required("Please enter job description"),
});
export const AddEduValidation = Yup.object().shape({
  education: Yup.string().required("Please select education"),
  university: Yup.string().required("Please select university"),
  fieldOfStudy: Yup.string().required("Please select field of study"),

});

export const AddCorporateValidation = Yup.object({
  companyName: Yup.string().required("Please enter company name"),
  yearReg: Yup.string().required("Please enter year of registration"),
  companyRegNo: Yup.string().notRequired(),
  address1: Yup.string().required("Please enter address 1")
              .min(10,"Address line 1 should have minimum 10 and maximum 150 characters")
              .max(150,"Address line 1 should have minimum 10 and maximum 150 characters"),
  address2: Yup.string().required("Please enter address 2")
              .min(10,"Address line 2 should have minimum 10 and maximum 150 characters")
              .max(150,"Address line 2 should have minimum 10 and maximum 150 characters"),
  country: Yup.string().required("Please select country"),
  city: Yup.string().required("Please enter or select city"),
  state: Yup.string().required("Please enter or select state"),
  postalCode: Yup.string().required("Please enter postal code"),
  companyProfile: Yup.string().required("Please enter company profile"),
  companyWebsite: Yup.string().notRequired(),
  awardCertification: Yup.string().notRequired().nullable(),
  experience: Yup.string().notRequired(),
  corporateIndustry: Yup.string().required("Please select industry"),
  overview: Yup.string().required("Please enter company overview"),
  noOfEmp: Yup.string().required("Please enter no. of employees"),
  vat_tax: Yup.string().required("Please enter vat id"),
 });

interface Iexperience {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      job_title: string;
      description: string;
      profile_id: number;
      country: string;
      state: string;
      city: string;
      employer_name: string;
     };
  };
}
interface Ieducation {
  data: {
    id: string,
    type: string,
    attributes: {
        id: number,
        school_name: string | null,
        start_date: null,
        end_date: null,
        grades: string | null,
        description: string | null,
        make_grades_public: false,
        profile_id: number,
        currently_studying_here: null,
        university_college: string | null,
        field_study: string | null,
        education: string | null,
        college_id_image: null,
    }
}
}
 


export interface IResume {
  url: string;
  name: string;
  created_at: string | null;
  size: number;
  blob_id: number;
}

interface CurrencyList{
           id: string,
          type: string,
          attributes: {
              name: string,
              currency: string,
              currency_symbol: string,
          }
     }

interface Skill {
  id: number;
  selected?: boolean;
  name: string;
 }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  typeOfRole?: string;
  // Customizable Area End
}

interface S {
  industryname:any;
  apidata:any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profilePic: Blob | null;
  profilePicImgUrl: string;
  profileCorporatePic: Blob | null;
  profileCorporatePicImgUrl: string;
  hourlyChargesValueData: any;
  addWorkExpData:any;
  hourlyChargesType: number;
  numberOfHoursValue: string;
  numberOfHoursType: number;
  authToken: string | null;
  currentUserDetails: any;
  freelanceexperience: string;
  fieldOfStudyData: any;
  userUniversityData: any;
  freelancerExpData:string[];
  educationData:any,
  isAddAnotherexperienceBtnClicked: boolean;
  isAddAnotherEduBtnClicked: boolean;
  certifications: { name: string }[];
  certificateName: string;
  basicDetailsUpdateStatus: boolean;
  skills: { id: number; name: string }[];
  experiencesData: Iexperience[];
 educationQualificationData:Ieducation[];
  carrerexperienceId: string | null;
  educationId: string | null;
  uploadedResume: File[];
  uploadedNewResume:File[];
  hourlyChargesStatus: boolean;
  renderDetailsView: string;
  personalDetailsUpdateStatus: boolean;
  companyId:string
  otherSkills: { id: number; name: string }[];
  getCountriesData:string[];
  getEnabledCountriesData:string[];
  getStatesData:string[];
  getEnabledStatesData:string[];
  getTimeZonesData:string[];
  cityData: string[];
  cityEnabledData:string[];
  addEducationData:any;
  //sponsor state
  paymentSelectedOption: string;
  //companydetails
  companyName: string;
  companyWebsite: string;
  companyContact: string;
  companyAddress: string;
  industryType: string;
  companySize: string;
  vatNumber: string;
  companyDetailsUpdateStatus: boolean;
  editExperianceClicked:boolean
  editEduClicked:boolean;
  rollId:number|null
  getSkillsData:any;
  skillList: Skill[];
  selectedSkill:Skill[];
  corporateSkillList: Skill[],
  corporateSelectedSkill:Skill[],
  categoryList:Skill[],
  selectedCategory:Skill[],
  newSkill:Skill[],
  isDisable:boolean,
error:{
  skills:string,
  category:string,
  yearsOfExperience:string,
  resumes:string,
  certification:string,
  workExperience: string,
},
resetData:any,
resetCorporateData:any;
corporateUserDetails: any;
freelancerCorporateId:string;
isLoadingStatesData:boolean;
isLoadingPersonalInfo:boolean;
isLoadingTimezone:boolean;
isLoadingStates:boolean;
isHourlyRateLoaded:boolean;
isRecordFound:boolean;
isEducationRecordFound:boolean;
selectedCountryCode:string;
chosenCreatedUniversity: string;
companyCountryCode:string;
currencyData:CurrencyList[];
openCreateUniversityModal:boolean;
createUniversityLoading:boolean;
universityList:IUniversityCollege[];
validateTitles: {
  jobTitle: string,
  education: string | number;
}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customisableuserprofiles2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileDetailsId: string = "";
  updateLancerBasicDetailsId: string = "";
  submitHourlyDetailsId: string = "";
  addAnotherexperienceId: string = "";
  addAnotherEducationId:string = "";
  deleteCarrerexperienceId: string = "";
  updateCarrerexperienceId: string = "";
  updateEducationId: string = "";
  deleteSkillId: string = "";
  deleteEducationId:string="";
  SavePersonaInfoDetailsId: string = "";
  updateCompanyDetailsId: string = "";
  getSkillsId: string = "";
  addNewSkillsId: string = "";
  removeDocumentsId:string = ''
  getSkillsApiCallId:string = '';
  getCategoryApiCallId:string = '';
  currentUserId:string="";
  getCountriesApiCallId:string="";
  getStateApiCallId :string="";
  getEnabledStateApiCallId:string="";
  getIndustryApiCallId:string='';
  getTimeZonesApiCallId:string=";"
  getCityApiCallId:string="";
  getFieldStudyApiCallId:string="";
  getEducationApiCallId:string="";
  getUniversityApiCallId:string="";
  getFreelancerExpApiCallId:string="";
  getSkillsListing:Skill[]=[];
  getCategoryListing:Skill[]=[];
  getCorporateSkillsListing:Skill[]=[];
  getCorporateCategoryListing:Skill[]=[];
  getProfileRole:string="";
  getFreelancerCorporateApiCallId:string="";
  updateFreelancerCorporateApiCallId:string="";
  getCount:number=0;
  getEnabledCountriesApiCallId:string="";
  getHourlyRatesApiCallId : string="";
  formikRef:React.RefObject<any>;
  formikRefEducation:React.RefObject<any>;
  getCurrencyApiCallId : string="";
  createUniversityApiCallId:string="";
  getUniversityListApiCallId:string="";
  freelanceExperience = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LoginUserMessage),
      // Customizable Area End
    ];

    this.state = {
      industryname:[],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profilePic: null,
      profilePicImgUrl: "",
      profileCorporatePic: null,
      profileCorporatePicImgUrl: "",
      hourlyChargesValueData:{hourlyChargesValue: ""},
      chosenCreatedUniversity: "",
      addWorkExpData:{
        jobDecritption: "",
        jobTitle: "",
        employerName: "",
        country: "",
        state:'',
        city: "",
      },
      addEducationData:{
        education: "",
        university: "",
        fieldOfStudy: "",
      },
      hourlyChargesType: 0,
      numberOfHoursValue: "",
      numberOfHoursType: 0,
      currentUserDetails: {
        firstName:"",
        lastName: "",
        gender: "",
        mobileNumber: "",
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        postalCode: "",
        birthdate: "",
        timeZone: "",
        profileEmail: "",
        currency:"",
        currencySymbol:'',
        countryCode:"",
        dialCode:""
      },
      freelanceexperience: "",
      fieldOfStudyData: [],
      userUniversityData:[],
      freelancerExpData:[],
      educationData:[],
      isAddAnotherexperienceBtnClicked: false,
      isAddAnotherEduBtnClicked:false,
      certifications: [],
      certificateName: "",
      basicDetailsUpdateStatus: false,
      skills: [],
      experiencesData: [],
      educationQualificationData:[],
      carrerexperienceId: null,
      educationId: null,
      uploadedResume: [],
      uploadedNewResume: [],
      authToken: "",
      hourlyChargesStatus: false,
      renderDetailsView: "basicInfo",
      paymentSelectedOption: "",
      companyName: "",
      companyWebsite: "",
      companyContact: "",
      companyAddress: "",
      industryType: "choose",
      companySize: "",
      vatNumber: "",
      companyDetailsUpdateStatus: false,
      personalDetailsUpdateStatus: false,
      editExperianceClicked: false,
      editEduClicked:false,
      companyId:'',
      rollId:null,
      getSkillsData:[],
      otherSkills:[],
      getCountriesData:[],
      getEnabledCountriesData:[],
      getStatesData:[],
      getEnabledStatesData:[],
      getTimeZonesData:[],
      cityData:[],
      cityEnabledData:[],
      skillList: [],
      selectedSkill:[],
      corporateSkillList: [],
      corporateSelectedSkill:[],
      categoryList: [],
      selectedCategory:[],
      newSkill:[],
      isDisable:false,
      error:{
        skills:"",
        category:"",
        yearsOfExperience:"",
        resumes:"",
        certification:"",
        workExperience: "",
      },
      resetData:[],
      resetCorporateData:[],
      corporateUserDetails: {
        companyName:"",
        yearReg: "",
        companyRegNo: "",
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        postalCode: "",
        companyProfile: "",
        companyWebsite: "",
        awardCertification: "",
        experience: "",
        corporateIndustry:"",
        overview:"",
        noOfEmp:"",
        vat_tax:'',
       },
       apidata:[],
       freelancerCorporateId:"",
       isLoadingStatesData:false,
       isLoadingPersonalInfo:false,
       isLoadingTimezone:false,
       isLoadingStates:false,
       isHourlyRateLoaded:false,
       isRecordFound:false,
       isEducationRecordFound:false,
       selectedCountryCode:"",
       companyCountryCode:'in',
       currencyData:[],
       createUniversityLoading:false,
       openCreateUniversityModal:false,
      universityList:[],
       validateTitles: {
        jobTitle: "",
        education: ""
       }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.formikRef=React.createRef()
    this.formikRefEducation=React.createRef()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      if(user){
        this.currentUserId=user?.id
      }
      
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.handleResponseWithoutErrors(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.handleResponseWithErrors(apiRequestCallId, errorReponse);
      } else if (!responseJson) {
        this.handleResponseWithUndefined(apiRequestCallId)
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId===this.getUniversityListApiCallId){
      this.handleUniversityListPayload(response);
    }
    this.handleCreateUniversityApiPayload(response, apiRequestCallId);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
 
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
    const token = await getStorageData("loginSuccessToken");
    const rollId = await getStorageData("rollId");
    this.checkSignUp();
    this.setState({ authToken: token, rollId });
    this.getCreatedUniversityList();
    this.getSkills();
    this.getCategory();
    this.getCountries();
    this.getEnabledCountries();
    this.getCurrency();
    this.getFieldOfStudyList();
    this.getEducationList();
    this.getUniversityList();
    this.getUserProfileDetails();
    this.getindustry()
    this.getFreelancerExperience();
    this.getHourlyRates();
  }
  changeResetUserMessage=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.ResetProfileMessage)
    );

    this.send(msg);
  }
  handleResponseWithoutErrors(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
    
     case this.getCountriesApiCallId:
          if(responseJson){
            this.setState({ getCountriesData: responseJson.countries_name });
           }
       break;
     case this.getIndustryApiCallId:
            this.setState({ industryname: responseJson.data });
       break;
     case this.getStateApiCallId:
        if(responseJson){
          this.setState({ isLoadingStates:false, getStatesData: responseJson.state_names});
            }
     break;
     case this.getEnabledStateApiCallId:
      if(responseJson){
        this.setState({ isLoadingStatesData:false , getEnabledStatesData: responseJson.state_names});
          }
   break;
     case this.getTimeZonesApiCallId:
        this.timezoneResponse(responseJson)
     break;
     case this.getFieldStudyApiCallId:
      if(responseJson){
        this.setState({ fieldOfStudyData: responseJson.data});
       }
     break;
     case this.getEducationApiCallId:
      if(responseJson){
        this.setState({ educationData: responseJson.data });
       }
     break;
     case this.getUniversityApiCallId:
      if(responseJson){
        this.setState({ userUniversityData: responseJson.data });
       }
     break;
     case this.getFreelancerCorporateApiCallId:
     this.getFreelancerResponse(responseJson)
     break;
      default:
        this.handleResponseWithoutErrorsMore(apiRequestCallId, responseJson)
        break;
    }
  }


  handleResponseWithoutErrorsMore(apiRequestCallId: string, responseJson: any) {
  
    switch (apiRequestCallId) {
      case this.getUserProfileDetailsId:
        this.handleGetUserProfileDetails(responseJson.data.attributes);
        this.setState({apidata:responseJson.data.attributes})
        break;
      case this.updateLancerBasicDetailsId:
        if (responseJson.data?.id !== ''){
          this.setState({isLoadingPersonalInfo:false})
          toast.success(configJSON.successProfileMsg)
          this.changeResetUserMessage()
        }
        break;
       case this.addAnotherexperienceId:
        this.setState({ isAddAnotherexperienceBtnClicked: false , isDisable:false}, () => {
          this.getUserProfileDetails();
        });
        toast.success("Record Added Successfully!")
        break;
      case this.addAnotherEducationId:
          this.setState({ isAddAnotherEduBtnClicked: false , isDisable:false}, () => {
            this.getUserProfileDetails();
          });
          toast.success("Record Added Successfully!")
          break;
      case this.deleteCarrerexperienceId:
        this.deleteMsg()
        break;
      case this.deleteEducationId:

          this.deleteMsg()
          break;
      case this.getSkillsApiCallId:
          if(responseJson.data.id !== ''){
            this.getSkillsData(responseJson.data)
          }
        break; 
      case this.getCategoryApiCallId:
         if(responseJson){
          this.getCategoryData(responseJson)
         }
        break;
      case this.SavePersonaInfoDetailsId:
          this.setState({ personalDetailsUpdateStatus: true });
          break;
      case this.removeDocumentsId:
          toast.success(configJSON.successfulDeleteMsg)
          this.getUserProfileDetails()
          break
      case this.updateEducationId:
            this.getUserProfileDetails();
            this.setState({ isAddAnotherEduBtnClicked: false, isDisable:false });
            toast.success("Updated Successfully!")
            break;
      case this.updateCompanyDetailsId:
          this.setState({ companyDetailsUpdateStatus: true });
          toast.success("Updated Successfully!")
          break;
     case this.getFreelancerExpApiCallId:
            if(responseJson){
              this.setState({ freelancerExpData: responseJson});
             }
           break;
        default:
          this. handleResponseWithoutErrorsMoreApi(apiRequestCallId, responseJson)
        break;
    }
  }

  handleResponseWithoutErrorsMoreApi(apiRequestCallId: string, responseJson: any) {
  
    switch (apiRequestCallId) {
      case this.updateCarrerexperienceId:
        this.getUserProfileDetails();
        this.setState({ isAddAnotherexperienceBtnClicked: false, isDisable:false });
        toast.success("Updated Successfully!")
        break;
      case this.getUserProfileDetailsId:
        this.handleGetUserProfileDetails(responseJson.data.attributes);
        break;
      case this.updateLancerBasicDetailsId:
       this.getUpdateResponse(responseJson.data)
        break;
      case this.updateFreelancerCorporateApiCallId:
          this.getUpdateResponse(responseJson.data)
          break;
      case this.submitHourlyDetailsId:
        if(responseJson.data.id !== ''){
          this.getHourlyDetails(responseJson.data)
        }
        break;
       case this.getEnabledCountriesApiCallId:
            if(responseJson){
              this.setState({ getEnabledCountriesData: responseJson.countries_name });
             }
         break; 
        case this.getHourlyRatesApiCallId:
 
            this.setState({
              hourlyChargesStatus: true,
                hourlyChargesValueData: {
                hourlyChargesValue: responseJson.data !== null ? (responseJson.data.attributes.charges || 0) : 0
              }
            });

       
          break; 
          case this.getCurrencyApiCallId:
            if(responseJson){
              this.setState({currencyData:responseJson.data})
             }
         break;       
        default:
        break;
    }
  }
  timezoneResponse=(responseJson:any)=>{
    if(responseJson){
      this.setState({ isLoadingTimezone:false,getTimeZonesData: responseJson});

      this.formikRef.current?.setFieldValue("timeZone",responseJson[0]|| 'No time zone for this country')
      this.setState(() => ({currentUserDetails: {...this.formikRef.current?.values,timeZone: responseJson[0]}}));
        }
  }
getFreelancerResponse=(resp:any)=>{
  if(resp){
    this.getFreelancerCorporateData(resp.data.attributes)
   }
}
getUpdateResponse=(data:any)=>{
  if (data.id !== ''){
    this.setState({isLoadingPersonalInfo:false})
    toast.success(configJSON.successProfileMsg)
  }
}
  getCategoryData=(response:any)=>{
    response.categories.map((item: any) => {
      this.state.categoryList.push({
        id: item.id,
        name: item.name,
      })
    })
  }

  resetForm = () => {
    if(this.state.apidata.company_details) {
    let filteredData = this.state.apidata.company_details.data.filter(
      (entry:any) => entry.attributes.company_type === 'primary_company'
    );
    if (filteredData) {
      this.setState({
        companyName:filteredData[0]?.attributes.company_name,
        companyWebsite:filteredData[0]?.attributes.company_website,
        companyContact:filteredData[0]?.attributes.company_contact,
        vatNumber:filteredData[0]?.attributes.vat_id,
        companyAddress:filteredData[0]?.attributes.address_line_1,
        companyId:filteredData[0]?.id,
        industryType:filteredData[0]?.attributes.other_industry || "choose",
        companySize:filteredData[0]?.attributes.number_of_employee,
       
      })
     }
    }
  };
  
  
  getHourlyDetails=(hourData:any)=>{
    this.setState({ isHourlyRateLoaded:false,hourlyChargesStatus: true, hourlyChargesValueData:{hourlyChargesValue: hourData.attributes.charges} });
    toast.success(configJSON.successHourlyRatesMsg)
  }

  getSkillsData=(res:any)=>{
    res.map((item: any) => {
      this.state.skillList.push({
        id: item.attributes.id,
        name: item.attributes.name,
      })
    })
  }
deleteMsg=()=>{
  toast.success(configJSON.successfulDeleteMsg)
  this.getUserProfileDetails();
}
  handleResponseWithErrors(apiRequestCallId: string, errorResponse: any) {
    if (apiRequestCallId === this.submitHourlyDetailsId) {
      this.setState({hourlyChargesStatus: false });
      toast.error(configJSON.errorHourlyRatesMsg)

    } 
    else if (apiRequestCallId === this.updateCompanyDetailsId) {
      alert(errorResponse.errors[0])
    }
    this.parseApiErrorResponse(errorResponse);
  }
  handleResponseWithUndefined(apiRequestCallId: string) {
    if (apiRequestCallId === this.getTimeZonesApiCallId) {
      this.setState({ isLoadingTimezone:false, getTimeZonesData: ['No time zone for this country']});

      this.formikRef.current?.setFieldValue("timeZone",'No time zone for this country')
      this.setState(() => ({currentUserDetails: {...this.formikRef.current?.values,timeZone: 'No time zone for this country'}}));
    }
  }

  handleDrop = (files: any) => {
    if (files[0].size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5 MB.")
       }
       else if (
        files[0].type !== "image/png" &&
        files[0].type !== "image/jpeg" &&
        files[0].type !== "image/jpg"
      ) {
        toast.error("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
      }
    else{
      this.setState({ profilePic: files[0] });
      const imgUrl = URL.createObjectURL(files[0]);
      this.setState({ profilePicImgUrl: imgUrl });
    }
  };

  getUserProfileDetails = async () => {
       const header = {
      token: this.state.authToken,
    };

    const getUserProfileRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileDetailsId = getUserProfileRequest.messageId;

    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileDetailsEndPoint}/${this.currentUserId}`
      
    );

    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getUserProfileRequest.id, getUserProfileRequest);
  };

  navigateToSpecificDetailsPage = (view: string) => {
    this.setState({ renderDetailsView: view });
  };
  
  getFreelancerCorporateData = (data: any) => {
    this.getStates(data.country)
    this.getCities(data.state)
    this.setState({resetCorporateData:data})
  const mappedCorporateData = {
    companyName:data.company_name,
    yearReg: data.year_of_registration,
    companyRegNo: data.registration_number,
    address1: data.address_line_1,
    address2: data.address_line_2,
    country: data.country,
    city: data.city,
    state: data.state,
    postalCode: data.postal_code,
    companyProfile: data.company_profile,
    companyWebsite: data.company_website,
    awardCertification: data.awards_won_by_company,
    experience: data.freelancing_experience,
    corporateIndustry:data.other_industry,
    overview:data.company_overview,
    noOfEmp:data.number_of_employee,
    vat_tax:data.vat_id,
   }

  this.setState({ corporateUserDetails: mappedCorporateData})
  data.logo != null && this.setState({ profileCorporatePicImgUrl:data.logo[0].url})

 // ---------------------- skills ----------------------------
 if(this.getProfileRole.toLowerCase().includes("corporate")){
 data.skills.map((list: any) => {
  this.state.selectedSkill.push({id: list.id,name: list.name})
})
 this.handleSetSkills()
 }

 if(this.getProfileRole.toLowerCase().includes("corporate")){
data.categories_interested.map((item: any) => {
  this.state.selectedCategory.push({id: item.id,name: item.name})
})
this.handleSetCategory()
 }
data.certification && data.certification.map((item: any) => {
  item !== "" && this.state.certifications.push({name: item})
  }) 

};
filteredEducationList=(educationList:any[]=[])=>{
  if(!educationList) {
    return []
  }
  return educationList.filter((educationItem)=>{
    if(educationItem.data.attributes.education){
      return true;
    }
    return false;
  });
}
filteredExperienceList=(experienceList:any[]=[])=>{
  if(!experienceList) {
    return []
  }
  return experienceList.filter((experienceItem)=>{
    if(experienceItem.data.attributes.job_title){
      return true;
    }
    return false;
  });
}
    handleGetUserProfileDetails = (data: any) => {
      this.getCount=this.getCount+1
      this.getProfileRole=data.role_name
      this.setState({resetData:data})
      this.getEnabledStates(data.country)
      this.getTimeZones(data.country)
      this.getEnabledCities(data.state)
    const mappedData = {
      firstName: data.first_name,
      lastName: data.last_name,
      gender: data.gender,
      mobileNumber: data.mobile_number,
      address1: data.address,
      address2: data.address_line_2,
      country: data.country,
      city: data.city,
      state: data.state,
      postalCode: data.postal_code,
      birthdate:data.date_of_birth,
      timeZone: data.time_zone,
      profileEmail: data.account_detail.data.attributes.email,
      currency: data.currency,
      currencySymbol:data.currency_symbol,
      countryCode:countryCodes?.find((item:any) => item?.country_name===data.country)
      ?.country_code.toUpperCase() || "" ,
      dialCode:"" || 91
    };
    let filteredSecData  = data?.company_details?.data?.filter(
      (entry:any) => entry.attributes.company_type === 'secondary_company'
    );
    if (filteredSecData) {
      this.setState({freelancerCorporateId:filteredSecData[0]?.id})
        this.getFreelancerCorporateDetails(filteredSecData[0]?.id);
       }
    let filteredData = data?.company_details?.data?.filter(
      (entry:any) => entry.attributes.company_type === 'primary_company'
    );
    this.setState({
      currentUserDetails: mappedData,
      experiencesData:this.filteredExperienceList(data.work_experiences),
      educationQualificationData:this.filteredEducationList(data.educational_qualification),
      companyId:data?.company_details?.data.id,
     });
     if (filteredData) {
      this.setState({
        companyName:filteredData[0]?.attributes.company_name,
        companyWebsite:filteredData[0]?.attributes.company_website,
        companyContact:filteredData[0]?.attributes.company_contact,
        companyAddress:filteredData[0]?.attributes.address_line_1,
        companyId:filteredData[0]?.id,
        vatNumber:filteredData[0]?.attributes.vat_id,
        industryType:filteredData[0]?.attributes.other_industry || "choose",
        companySize:filteredData[0]?.attributes.number_of_employee,
       
      })
     }
    data.photo != null && this.setState({ profilePicImgUrl:data?.photo[0]?.url})

  if(!this.getProfileRole.toLowerCase().includes("corporate")){
 
    this.getCount == 1 && data.skills.map((item: any) => {
      this.state.selectedSkill.push({id: item.id,name: item.name})
    })

     this.getCount > 1 && this.setState ({
      selectedSkill: [...new Set([ ...this.getSkillsListing])]
    });
    this.handleSetSkills()

    this.getCount == 1 && data.category_of_interests.map((item: any) => {
      this.state.selectedCategory.push({id: item.id,name: item.name})
    })
    this.getCount > 1 && this.setState ({
      selectedCategory: [...new Set([ ...this.getCategoryListing])]
    });
    this.handleSetCategory()

    this.getCount == 1 && data.certification.map((item: any) => {
      item !=="" && this.state.certifications.push({name: item})
      })
  }

    this.setState ({uploadedResume:[...data.uploaded_resumes, ... this.state.uploadedNewResume]})

    this.getCount == 1 && this.setState({freelanceexperience: data.experience_in_years });
    this.freelanceExperience = data.experience_in_years;
 };

handleSetSkills=()=>{
  this.setState((prev) => {
    const newSkill = prev.skillList.map((item) => {
      const isIncluded =
        this.state.selectedSkill.filter((subItem:any) => subItem.id === item.id).length > 0;
      if (isIncluded) {
        return {...item, selected: true};
       }
      return { ...item, selected: false};
      });
      return { skillList: newSkill };
      });
}
handleSetCategory=()=>{
  this.setState((prev) => {
    const newCategory = prev.categoryList.map((item) => {
      const isIncluded =
        this.state.selectedCategory.filter((subItem:any) => subItem.id === item.id).length > 0;
      if (isIncluded) {
        return {...item, selected: true};
       }
      return { ...item, selected: false};
      });
      return { categoryList: newCategory };
      });
}

 formCorporateValidate = () => {
  let isFormValid = true;
  if (this.getCorporateSkillsListing.length === 0) {
    this.setState(prevState => ({ error: { ...prevState.error,skills: "Please select the skill" }}));
    isFormValid = false;
  }
  if (this.getCorporateCategoryListing.length === 0) {
    this.setState(prevState => ({ error: { ...prevState.error,category: "Please select the category" }}));
    isFormValid = false;
  }
  return isFormValid
}

 handleUpdateFreelancerCorporateDetails=async (values: any) => {
  if (this.formCorporateValidate()) {
    this.setState({isLoadingPersonalInfo:true, error:{ ...this.state.error, skills:"", category:"" }});
    const formData = new FormData();
    this.state.profileCorporatePic != null && formData.append("data[attributes][logo]", this.state.profileCorporatePic );
    formData.append("data[attributes][company_name]", values.companyName);
    formData.append("data[attributes][year_of_registration]", values.yearReg);
    formData.append("data[attributes][registration_number]", values.companyRegNo);
    formData.append("data[attributes][address_line_1]", values.address1);
    formData.append("data[attributes][address_line_2]", values.address2);
    formData.append("data[attributes][country]", values.country);
    formData.append("data[attributes][state]", values.state);
    formData.append("data[attributes][city]", values.city);
    formData.append("data[attributes][postal_code]", values.postalCode);
    formData.append("data[attributes][company_profile]", values.companyProfile);
    formData.append("data[attributes][company_website]", values.companyWebsite);
    formData.append("data[attributes][awards_won_by_company][]", values.awardCertification);
    formData.append("data[attributes][freelancing_experience]", this.state.freelanceexperience || this.freelanceExperience);
    formData.append("data[attributes][company_overview]", values.overview);
    formData.append("data[attributes][other_industry]",  values.corporateIndustry);
    formData.append("data[attributes][number_of_employee]",  values.noOfEmp);
    formData.append("data[attributes][vat_id]",  values.vat_tax);
 
    this.getCorporateSkillsListing.forEach((skill:any) => {
      formData.append(`data[attributes][skill_id][]`, skill.id);
    });
    this.getCorporateCategoryListing.forEach((cat:any) => {
      formData.append(`data[attributes][category_of_interest_id][]`, cat.id);
    });
    this.state.certifications.length == 0 &&  formData.append("data[attributes][certification][]", "");
    this.state.certifications.length > 0 && this.state.certifications.forEach((certificate:any) => {
      formData.append(`data[attributes][certification][]`, certificate.name);
    });
    const header = {
      token: this.state.authToken,
    };

    const updateDetailRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateFreelancerCorporateApiCallId = updateDetailRequest.messageId;

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCorporateDetailsEndPoint}/${this.state.freelancerCorporateId}`
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(updateDetailRequest.id, updateDetailRequest);
  }
}



 handleUpdateBasicDetails = async (values: any) => {

  this.setState({isLoadingPersonalInfo:true})
    const formData = new FormData();
    this.state.profilePic != null && formData.append("[profile][photo]", this.state.profilePic );
    formData.append("[profile][first_name]", values.firstName);
    formData.append("[profile][last_name]", values.lastName);
    formData.append("[profile][gender]", values.gender);
    formData.append("[profile][date_of_birth]", values.birthdate);
    formData.append("[profile][mobile_number]", values.mobileNumber);
    formData.append("[profile][address]", values.address1);
    formData.append("[profile][address_line_2]", values.address2);
    formData.append("[profile][country]", values.country);
    formData.append("[profile][time_zone]", values.timeZone);
    formData.append("[profile][state]", values.state);
    formData.append("[profile][city]", values.city);
    formData.append("[profile][postal_code]", values.postalCode);
    formData.append("[profile][currency]", values.currency);
    formData.append("[profile][currency_symbol]", values.currencySymbol);
    
    const header = {
      token: this.state.authToken,
    };

    const updateDetailRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateLancerBasicDetailsId = updateDetailRequest.messageId;

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateBasicDetailsEndPoint}/${this.currentUserId}`
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(updateDetailRequest.id, updateDetailRequest);
  };
  submitHoursAndAvailabilty = async (values:any) => {
    this.setState({isHourlyRateLoaded:true})
    const httpBody = {
      "charges": values.hourlyChargesValue,
     };

    const header = {
      token: this.state.authToken,
     "Content-Type": "application/json",
    };

    const hourlyDetailsAndAvailabiltyRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitHourlyDetailsId = hourlyDetailsAndAvailabiltyRequest.messageId;

    hourlyDetailsAndAvailabiltyRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hourlyDetailsEndPoint
    );

    hourlyDetailsAndAvailabiltyRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    hourlyDetailsAndAvailabiltyRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    hourlyDetailsAndAvailabiltyRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(
      hourlyDetailsAndAvailabiltyRequest.id,
      hourlyDetailsAndAvailabiltyRequest
    );
  };
  formValidate = () => {
    let isFormValid = true;
    
    if (this.getSkillsListing.length === 0) {
      this.setState(prevState => ({ error: { ...prevState.error,skills: "Please select the skill" }}));
      isFormValid = false;
    }
    if (this.getCategoryListing.length === 0) {
      this.setState(prevState => ({ error: { ...prevState.error,category: "Please select the category" }}));
      isFormValid = false;
    }
    if (this.state.uploadedResume.length === 0) {
      this.setState(prevState => ({ error: { ...prevState.error,resumes: "Please add the file"}}));
      isFormValid = false;
    }
     if (this.state.certifications.length === 0) {
      this.setState(prevState => ({ error: { ...prevState.error,certification: "Please add certification" }}));
      isFormValid = false;
    }
    if(this.state.freelanceexperience === "") {
      this.setState(prevState => ({ error: { ...prevState.error, yearsOfExperience: "Please add freelancing experience"}}));
      isFormValid = false;
    }
    if(this.state.freelanceexperience != "0" && this.state.experiencesData.length == 0) {
      this.setState(prevState => ({ error: { ...prevState.error, workExperience: "Please add work experience since your freelancing experience is not 0."}}));
      isFormValid = false;
    }
    return isFormValid
  }
  handleSavePersonaInfoDetails = async () => {
    if (this.formValidate()) {
      this.setState({     error:{
        skills:"",
        category:"",
        yearsOfExperience:"",
        resumes:"",
        certification:"",
        workExperience: "",
      },isLoadingPersonalInfo:true,
    });


    const formData = new FormData()
    
    this.getSkillsListing.forEach((skill:any) => {
      formData.append(`profile[skill_id][]`, skill.id);
    });
    this.getCategoryListing.forEach((cat:any) => {
      formData.append(`profile[category_of_interest_id][]`, cat.id);
    });

   
    this.state.uploadedNewResume.forEach((file:any) => {
      formData.append(`profile[resumes][]`, file);
    });

    this.state.certifications.forEach((certificate:any) => {
      formData.append(`profile[certification][]`, certificate.name);
    });

    formData.append('profile[experience_in_years]', this.state.freelanceexperience || this.freelanceExperience)

    const header = {
      token: this.state.authToken,
    };

    const updateDetailRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateLancerBasicDetailsId = updateDetailRequest.messageId;

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateBasicDetailsEndPoint}/${this.currentUserId}`
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(updateDetailRequest.id, updateDetailRequest);
 }
 
};


  //Personal Info Page controller

  handleExperienceChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "freelanceexperience") {
      this.handleFreelanceExperience(value);
    }
  };

  addAnotherExperinceBtnClicked = () => {
    this.setState({
      isAddAnotherexperienceBtnClicked: !this.state
        .isAddAnotherexperienceBtnClicked,
        editExperianceClicked:false,
        isDisable:true,
        isRecordFound:true,
        addWorkExpData:{
          jobDecritption: "",
          jobTitle: "",
          employerName: "",
          country: "",
          state:'',
          city: "",
        },
        
    });
  };
  addAnotherEduBtnClicked= () => {
    this.setState({
      isAddAnotherEduBtnClicked: !this.state
        .isAddAnotherEduBtnClicked,
         editEduClicked:false,
         isDisable:true,
         isEducationRecordFound:true,
        addEducationData:{
          ...this.state.addEducationData,
          education: "",
          university: "",
          fieldOfStudy: "",
        },
    });
  };
 
  handleAddCertifications = (event: any) => {
    if (event.key === "Enter") {
        event.preventDefault()
      const { certifications, certificateName } = this.state;
      if (certificateName.trim() !== "") {
        const isDuplicate = certifications.some(
          (certification) => certification.name === certificateName
        );
 
      if (!isDuplicate) {
        let newCertification = { name: certificateName };
  
        this.setState((prevState) => ({
          certifications: [...prevState.certifications, newCertification],
          certificateName: '',
        }));
      }
    }
  }
  };

  handleUploadResume = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    this.setState((prevState) => ({ error: { ...prevState.error, resumes: "" } }));
  
    if (files) {
      const allowedExtensions = [".pdf", ".doc", ".docx", ".png", ".jpg", ".jpeg"];
      const selectedFiles = Array.from(files);

      for (const file of selectedFiles) {
        const fileExtension = file.name.split(".").pop();
        if (!allowedExtensions.includes(`.${fileExtension}`)) {
           toast.error(configJSON.errorUploadResume)
          return; 
        }
      }
      this.setState({
        uploadedResume: [...this.state.uploadedResume, ...selectedFiles],
        uploadedNewResume: [...this.state.uploadedNewResume, ...selectedFiles],
      });
    }
  };
  
  handleAddEducation = async (values: any) => {
    this.setState({isEducationRecordFound:false})
    const formData = new FormData();
    formData.append("[profile][education_id]", values.education);
    formData.append('[profile][university_college_id]', values.university)
    formData.append("profile[field_study_id]", values.fieldOfStudy);

    const header = {
      token: this.state.authToken,
    };

    const updateDetailRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAnotherEducationId = updateDetailRequest.messageId;

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAnotherEducationEndpoint
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(updateDetailRequest.id, updateDetailRequest);
  };

  handleAddCarrerExperience = async (values: any) => {
    const formData = new FormData();
    const header = {token: this.state.authToken};

    formData.append("[profile][job_title]", values.jobTitle);
    formData.append('[profile][employer_name]', values.employerName)
    formData.append("[profile][city]", values.city);
    formData.append('[profile][state]', values.state)
    formData.append('[profile][country]', values.country)
    formData.append("[profile][description]", values.jobDecritption);

    this.setState({isRecordFound:false, uploadedNewResume:[]}) 

    const updateDetailRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAnotherexperienceId = updateDetailRequest.messageId;

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAnotherWorkExperianceEndpoint
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(updateDetailRequest.id, updateDetailRequest);
  };
  

  updateCarrerexperience = (experienceData: any) => {
    this.getStates(experienceData.data.attributes.country)
    this.getCities(experienceData.data.attributes.state)
    this.setState(prevState => ({
      addWorkExpData: {
        ...prevState.addWorkExpData,
        jobDecritption: experienceData.data.attributes.description,
        jobTitle: experienceData.data.attributes.job_title,
        employerName: experienceData.data.attributes.employer_name,
        country: experienceData.data.attributes.country,
        state: experienceData.data.attributes.state,
        city: experienceData.data.attributes.city,
      },
      isAddAnotherexperienceBtnClicked: !prevState.isAddAnotherexperienceBtnClicked,
      carrerexperienceId: experienceData.data.id,
      editExperianceClicked: true,
      isDisable:true,
    }));
  };

  updateEducation = (experienceData: any) => {
    const selectedEducation = experienceData?.data?.attributes?.education;
    const selectedUniversity = experienceData?.data?.attributes?.university_college;
    const prevSelectedUniversityId = experienceData?.data?.attributes?.university_college_id;
    const selectedFieldStudy = experienceData?.data?.attributes?.field_study;
  
    const selectedEducationId = this.state.educationData.find((o: any) => o.attributes.education === selectedEducation)?.attributes.id;
    let selectedUniversityId = this.state.userUniversityData.find((o: any) => o.attributes.name === selectedUniversity)?.attributes.id;
    const selectedFieldStudyId = this.state.fieldOfStudyData.find((o: any) => o.attributes.field_of_study === selectedFieldStudy)?.attributes.id;
  
    let updatedUniversity=this.state.userUniversityData;
    if(!selectedUniversityId){
      const newUniversity={
        "id": prevSelectedUniversityId,
        "type": "university_college",
        "attributes": {
          "id": Number(prevSelectedUniversityId),
          "name":selectedUniversity
        }
      }
      updatedUniversity=[newUniversity,...updatedUniversity];
      selectedUniversityId=prevSelectedUniversityId;
    }

    if (selectedEducationId && selectedUniversityId && selectedFieldStudyId) {
      this.setState({
        addEducationData: {
          ...this.state.addEducationData,
          education: selectedEducationId,
          university: selectedUniversityId,
          fieldOfStudy: selectedFieldStudyId,
        },
        isAddAnotherEduBtnClicked: !this.state.isAddAnotherEduBtnClicked,
        educationId: experienceData?.data?.attributes?.id,
        editEduClicked: true,
        isDisable: true,
        isEducationRecordFound:true,
        userUniversityData:updatedUniversity
      });
    }
  };
  
    
 handleDownloadResume = async (url: string, fileName: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(fileUrl);
  };

  deleteCarrerexperience = async (id: number) => {
    if(this.state.freelanceexperience != "0") {
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          workExperience: "Please add work experience since your freelancing experience is not 0.",
          yearsOfExperience: "",
        },
        isDisable: true,
      }));
    }
    const header = {
      token: this.state.authToken,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const deleteCarrerexperienceRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCarrerexperienceId = deleteCarrerexperienceRequest.messageId;

    deleteCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCarrerExperience}${id}`
    );

    deleteCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    deleteCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(
      deleteCarrerexperienceRequest.id,
      deleteCarrerexperienceRequest
    );
  };
  deleteEducation= async (id: number) => {
    const header = {
      token: this.state.authToken,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const deleteCarrerexperienceRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteEducationId = deleteCarrerexperienceRequest.messageId;

    deleteCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteEducationApiUrl}${id}`
    );

    deleteCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    deleteCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(
      deleteCarrerexperienceRequest.id,
      deleteCarrerexperienceRequest
    );
  };
  handleUpdateCarrerexperienceApi = async (values:any) => {
    this.setState({uploadedNewResume:[], isRecordFound:false})
    const formData = new FormData();
    formData.append("career_experience[job_title]", values.jobTitle);
    formData.append('career_experience[employer_name]', values.employerName)
    formData.append("career_experience[city]", values.city);
    formData.append('career_experience[country]', values.country)
    formData.append("career_experience[description]", values.jobDecritption);
    formData.append('career_experience[state]', values.state)

    const header = {
      token: this.state.authToken,
    };

    const updateCarrerexperienceRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCarrerexperienceId = updateCarrerexperienceRequest.messageId;

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCarrerExperience}/${this.state.carrerexperienceId}`
    );

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(
      updateCarrerexperienceRequest.id,
      updateCarrerexperienceRequest
    );
  };

  handleUpdateEducation = async (values:any) => {
    this.setState({uploadedNewResume:[],isEducationRecordFound:false})
    const formData = new FormData();
    formData.append("[profile][education_id]", values.education);
    formData.append('[profile][university_college_id]', values.university)
    formData.append("profile[field_study_id]", values.fieldOfStudy);

    const header = {
      token: this.state.authToken,
    };

    const updateCarrerexperienceRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEducationId = updateCarrerexperienceRequest.messageId;

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateEducationApiCall}${this.state.educationId}`
    );

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateCarrerexperienceRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(
      updateCarrerexperienceRequest.id,
      updateCarrerexperienceRequest
    );
  };

  deleteCertification = (fileId: number) => {
    let updatedCertificates = this.state.certifications.filter( (file:any, index) => index !== fileId );
    this.setState({ certifications: updatedCertificates});
     };


    removeDocuments = async (id:number, fileIndex:number)  => {
    if(!id){
      const updatedUploadedResume = this.state.uploadedResume.filter((file, index) => index !== fileIndex);
      const updatedNewUploadedResume = updatedUploadedResume.filter((file:any) => !file.blob_id);
      this.setState({ uploadedResume:[...updatedUploadedResume], uploadedNewResume:[...updatedNewUploadedResume] });

      }
     else{
      const httpBody = {
        profile_id: this.currentUserId,
        "blob_id": id,
        "type":"resume"
      };
  
      const header = {
        token: this.state.authToken,
        "Content-Type": configJSON.exampleApiContentType,
      };
  
      const removeDocumentRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.removeDocumentsId = removeDocumentRequest.messageId;
  
      removeDocumentRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.removeDocumentsEndPoint
      );
  
      removeDocumentRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
  
      removeDocumentRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteMethod
      );
  
      removeDocumentRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      runEngine.sendMessage(removeDocumentRequest.id, removeDocumentRequest);
    }
   
  }
 
  //Sponsor handling

  handlePaymentOptionChange = (event: any) => {
    this.setState({ paymentSelectedOption: event.target.value });
  };

  handleUpdateCompanyDetails = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();

    const isPhoneNumberValid = isValidPhoneNumber(this.state.companyContact, this.state.companyCountryCode as CountryCode);
    if(!isPhoneNumberValid) {
      toast('Phone number invalid');
      return;
    }

    formData.append("data[attributes][company_name]",  this.state.companyName);
    formData.append("data[attributes][company_website]",  this.state.companyWebsite);
    formData.append("data[attributes][address_line_1]",  this.state.companyAddress);
    formData.append("data[attributes][company_contact]",  this.state.companyContact);
    formData.append("data[attributes][other_industry]",  this.state.industryType);
    formData.append("data[attributes][number_of_employee]",  this.state.companySize);
    formData.append("data[attributes][company_size]",  this.state.companySize);


    const header = {
      token: this.state.authToken,
    };

    const updateCompanyDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCompanyDetailsId = updateCompanyDetailsRequest.messageId;

    updateCompanyDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCompanyDetailsEndpoint}${this.state.companyId}`
    );

    updateCompanyDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateCompanyDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    updateCompanyDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(
      updateCompanyDetailsRequest.id,
      updateCompanyDetailsRequest
    );
  };

  //get industry api

  getindustry = async() => {
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndustryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getindustrylist
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // get skills api start
  getSkills = async() => {
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSkillsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSkillsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // get skills api end
    // get category api start
    getCategory = async() => {
      const token =   await getStorageData('loginSuccessToken', false)
      const header = {
        token: token,
        "Content-Type": configJSON.exampleApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getCategoryApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCategoryEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // get category api end
    // get countries api start
    getCountries = async() => {
      const token =   await getStorageData('loginSuccessToken', false)
      const header = {
        token: token,
        "Content-Type": configJSON.exampleApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getCountriesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCountriesEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // get countries api end
    // get state api start
    getStates = async(countryName:string) => {
      this.setState({isLoadingStates:true})
      const token =   await getStorageData('loginSuccessToken', false)
      const header = {
        token: token,
        "Content-Type": configJSON.exampleApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getStateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getStateEndPoint}${countryName}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // get state api end
    // get enabled state api start
    
    getEnabledStates = async(country:string) => {
      this.setState({isLoadingStatesData:true})
      const token =   await getStorageData('loginSuccessToken', false)
      const header = {
        token: token,
        "Content-Type": configJSON.exampleApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getEnabledStateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getEnabledStateApiUrl}${country}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // get enabled state api end
        // get timezones api start
        getTimeZones = async(countryName:string) => {
          this.setState({isLoadingTimezone:true})
          const token =   await getStorageData('loginSuccessToken', false)
          const header = {
            token: token,
            "Content-Type": configJSON.exampleApiContentType,
          };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getTimeZonesApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTimeZonesEndPoint}${countryName}`
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        };
        // get state api end
  // get field of study api start
  getFieldOfStudyList = async() => {
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFieldStudyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFieldStudyEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // get field of study api end

    // get education api start
    getEducationList = async() => {
      const token =   await getStorageData('loginSuccessToken', false)
      const header = {
        token: token,
        "Content-Type": configJSON.exampleApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getEducationApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getEducationEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // get education api end
 
      // get education api start
      getUniversityList = async() => {
        const token =   await getStorageData('loginSuccessToken', false)
        const header = {
          token: token,
          "Content-Type": configJSON.exampleApiContentType,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getUniversityApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getUniversityPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      // get education api end


            // get freelancer corporate api start
            getFreelancerCorporateDetails = async(id:string) => {
              const token =   await getStorageData('loginSuccessToken', false)
              const header = {
                token: token,
              };
          
              const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
              );
          
              this.getFreelancerCorporateApiCallId = requestMessage.messageId;
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getFreelancerCorporatePoint}/${id}`
              );
          
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
              );
          
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.httpGetMethod
              );
              runEngine.sendMessage(requestMessage.id, requestMessage);
            };
            // get freelancer corporate api end
      // get freelancer api start
      getFreelancerExperience = async() => {
        const token =   await getStorageData('loginSuccessToken', false)
        const header = {
          token: token,
          "Content-Type": configJSON.exampleApiContentType,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getFreelancerExpApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getFreelancerExpApiCallUrl
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      // get freelancer exp api end
          // get countries api start
    getEnabledCountries = async() => {
      const token =   await getStorageData('loginSuccessToken', false)
      const header = {
        token: token,
        "Content-Type": configJSON.validationApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getEnabledCountriesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getEnabledCountriesApiUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // get countries api end
          // get hourly rate api start
          getHourlyRates = async() => {
            const token =   await getStorageData('loginSuccessToken', false)
            const header = {
              token: token,
              "Content-Type": configJSON.exampleApiContentType,
            };
        
            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
        
            this.getHourlyRatesApiCallId = requestMessage.messageId;
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.getHourlyRatesApiCallUrl
            );
        
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );
        
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.httpGetMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
          };
          // get hourly rate api end

            // get currency api start
            getCurrency = async() => {
              const token =   await getStorageData('loginSuccessToken', false)
              const header = {
                token: token,
                "Content-Type": configJSON.exampleApiContentType,
              };
          
              const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
              );
          
              this.getCurrencyApiCallId = requestMessage.messageId;
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getCurrencyApiCallUrl
              );
          
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
              );
          
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.httpGetMethod
              );
              runEngine.sendMessage(requestMessage.id, requestMessage);
            };
            // get currency api end
  resetFormValues = (resetForm:any) => {
    resetForm(this.state.currentUserDetails);
  };
  
  resetHoursFormValues = (resetForm:any) => {
    resetForm(this.state.hourlyChargesValueData);
  };
  onKeyPressAvoidNumbers=(e:React.KeyboardEvent<HTMLInputElement>) => {
    const numericInputRegex = new RegExp(configJSON.avoidNumbersPattern);
    if (!numericInputRegex.test(e.key)) {
    e.preventDefault();
  }}

  handleCountryCodeChange=(country_name:string)=>{
    const countryCode =
    countryCodes?.find((item:any) => item?.country_name===country_name)
      ?.country_code || "";
this.setState({selectedCountryCode:countryCode})
  }

  onCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
   
    const selectedCountry = event.target.value;
    const findCurrencyValue=this.state.currencyData.find((item:any)=>item.attributes?.name===selectedCountry);

    this.setState({cityEnabledData:[]})
    this.setState({getEnabledStatesData:[]})
    this.formikRef.current?.setFieldValue("mobileNumber",'')
    this.setState((prevState) => ({
      ...prevState,
      currentUserDetails: {
        ...this.formikRef.current?.values,
        country: selectedCountry,
        timeZone: "",
        state:"",
        city:'',
        mobileNumber:'',
        currency:findCurrencyValue?.attributes.currency,
        currencySymbol:findCurrencyValue?.attributes.currency_symbol,
       },
      selectedCountry,
    }),()=>{setTimeout(() => {
      this.handleCountryCodeChange(selectedCountry)
    }, 500);});
    this.getTimeZones(selectedCountry)
    this.getEnabledStates(selectedCountry); 
   };

   onPhoneChange= (value: string, data:{dialCode:string,countryCode:string}) => {
    this.setState ({currentUserDetails: { ...this.state.currentUserDetails, mobileNumber: value, "countryCode":data.countryCode.toUpperCase() ||this.state.selectedCountryCode ,"dialCode":data.dialCode}});
   };


  onCertificationChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({ certificateName: event.target.value })
    this.setState(prevState => ({ error: { ...prevState.error,certification: "" }}));
  }
 
  onTimeZoneChange= (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTimeZone = event.target.value;
       this.setState(() => ({currentUserDetails: { ...this.formikRef.current?.values,timeZone: selectedTimeZone}}));
   };

  onStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = event.target.value;
    this.setState({cityEnabledData:[]})
    this.setState(() => ({
  
      currentUserDetails: {
        ...this.formikRef.current?.values,
        state: selectedState,
        city:"",
      },

    }));
    this.state.getEnabledStatesData.length > 0 && this.getEnabledCities(selectedState)
   };

   getCities = (selectedState:string)=>{
    let cityNames:string[]=[];
    for(let city of cityList){
     
      if(city.state_name.includes(selectedState)){
        cityNames.push(city.name)
      }
    }
    this.setState({cityData:cityNames})
   }
   onCityChange= (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
    this.setState ({currentUserDetails: { ...this.state.currentUserDetails, city: event.target.value }});
   };
   getEnabledCities = (selectedEnabledState:string)=>{
    let cityListing:string[]=[];
    for(let city of cityList){
     
      if(city.state_name.includes(selectedEnabledState)){
        cityListing.push(city.name)
      }
    }
    this.setState({cityEnabledData:cityListing})
   }
   removeCitites=()=>{
    this.setState({cityData:[]})
   }
   removeStates=()=>{
    this.setState({getStatesData:[]})
   }
   selectNewElement = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value
    this.setState(prevState => ({ error: { ...prevState.error,skills: "" }}));
    this.setState((prev) => {
      const newSkill = prev.skillList.map((item) => {
        if (item.id === Number(id)) {
          return { ...item, selected: true};
        }
        return item;
      });

  return { skillList: newSkill};
  });
  };

  selectNewCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value
    this.setState(prevState => ({ error: { ...prevState.error,category: "" }}));
    this.setState((prev) => {
      const newCategory = prev.categoryList.map((item) => {
        if (item.id === Number(id)) {
          return { ...item, selected: true};
        }
        return item;
      });

  return { categoryList: newCategory};
  });
  };

  deleteSelected = (id: number) => {
    this.setState((prev) => {
      const newSkill = prev.skillList.map((item) => {
        if (item.id === Number(id)) {
          return {
            ...item,
            selected: false,
          };
        }
        return item;
      });

  return {
    skillList: newSkill,
  };
});
  };

  deleteSelectedCategory = (id: number) => {
    this.setState((prev) => {
      const newCategory = prev.categoryList.map((item) => {
        if (item.id === Number(id)) {
          return {
            ...item,
            selected: false,
          };
        }
        return item;
      });

  return {
    categoryList: newCategory,
  };
});
  };

  resetWorkExpFormValues = () => {
    this.setState({ isAddAnotherexperienceBtnClicked: false, isDisable:false , isRecordFound:false});
  };

  resetEducationFormValues= () => {
    this.setState({ isAddAnotherEduBtnClicked: false , isDisable:false, isEducationRecordFound:false})
  };
  handleResetData=()=>{
     this.setState({ freelanceexperience: this.state.resetData.experience_in_years,
      experiencesData: this.filteredExperienceList(this.state.resetData.work_experiences),
      educationQualificationData:this.filteredEducationList(this.state.resetData.educational_qualification), 
       });

      this.handleResetSkills(this.state.resetData)
     this.handleResetCategories(this.state.resetData)

    this.setState({ uploadedResume: [...this.state.resetData.uploaded_resumes || []] });

    this.handleResetCertifications(this.state.resetData)
  }
handleResetCertifications=(certi_data:any)=>{
  if(!certi_data.certification) {
    return 
  }
  this.setState({
    certifications: certi_data.certification.filter((item:any)=>{
      if(item){
        return true
      }
      return false
    }).map((cert:string) => ({
      name: cert,
    })),
  });
}
handleResetSkills = (skills_data: any) => {
  const skillsWithRequiredProperties = skills_data?.skills?.map((skill: any) => ({
    id: skill.id,
    name: skill.name
  })) || [];

  const updatedSelectedSkill = [...this.state.selectedSkill, ...skillsWithRequiredProperties];

  this.setState({
    selectedSkill: updatedSelectedSkill
  });

  this.setState((prev) => {
    const newSkill = prev.skillList.map((item) => {
      const isIncluded = updatedSelectedSkill.some((subItem) => subItem.id === item.id);
      return { ...item, selected: isIncluded };
    });
    return { skillList: newSkill };
  });
};

handleResetCategories = (cat_data: any) => {
  const catWithRequiredProperties = cat_data?.category_of_interests?.map((cat: any) => ({
    id: cat.id,
    name: cat.name
  })) || [];

  const updatedSelectedCat = [...this.state.selectedCategory, ...catWithRequiredProperties];

  this.setState({
    selectedCategory: updatedSelectedCat
  });

  this.setState((prev) => {
    const newCat = prev.categoryList.map((item) => {
      const isIncluded = updatedSelectedCat.some((subItem) => subItem.id === item.id);
      return { ...item, selected: isIncluded };
    });
    return { categoryList: newCat };
  });
};


onChangeCorporateCountry=(e: React.ChangeEvent<HTMLSelectElement>, setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined)=>void)=>{
  setFieldValue(`country`,e.target.value);
  setFieldValue(`state`,"");
  setFieldValue(`city`,"");
  this.removeStates()
  this.removeCitites()
  this.getStates(e.target.value)
}

onChangeCorporateState=(e: React.ChangeEvent<HTMLSelectElement>, setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined)=>void)=>{
  setFieldValue(`state`,e.target.value);
   setFieldValue(`city`,"");
   this.removeCitites()
   this.state.getStatesData.length > 0 && this.getCities(e.target.value)
}

handleCorporateFilesDrop = (files: any) => {
  if (files[0].size > 5 * 1024 * 1024) {
    toast.error("File size exceeds 5 MB.")
   }
   else if (
    files[0].type !== "image/png" &&
    files[0].type !== "image/jpeg" &&
    files[0].type !== "image/jpg"
  ) {
    toast.error("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
  }
else{
  this.setState({ profileCorporatePic: files[0] });
  const imgUrl = URL.createObjectURL(files[0]);
  this.setState({ profileCorporatePicImgUrl: imgUrl });
}
};


resetCorporateFormValues = (resetForm:(nextState?: Partial<FormikState<any>> | undefined)=>void) => {
  resetForm(this.state.corporateUserDetails);
  this.handleResetSkills(this.state.resetCorporateData)
  this.handleResetCategories(this.state.resetCorporateData)
  this.handleResetCertifications(this.state.resetCorporateData)
};
onConvertResumeFileSize=(bytes:number)=>{
  if (bytes >= 1024 * 1024) {
   return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
 } else {
   return (bytes / 1024).toFixed(2) + ' KB';
 }
}
truncateResumeName(name: string) {
  return name.length > 16 ? `${name.substring(0, 16)}...` : name;
}

handlePhoneContact = (value: string, countryObj: any, e: any, formattedValue: string | undefined) => {
  const { companyCountryCode } = this.state;
  if (companyCountryCode === countryObj?.countryCode ) {
    this.setState({ companyContact: formattedValue || '' });
  } else {
      this.setState({ companyContact: '', companyCountryCode: countryObj?.countryCode });
  }
 };

 onCurrencyChange= (event: any) => {
   
  const selectedCurrency = event.target.value;
  const selectedItem = this.state.currencyData.find((item:any) => item.attributes.currency === selectedCurrency);
  const selectedSymbol = selectedItem ? selectedItem.attributes.currency_symbol : '';
 
  this.setState(() => ({currentUserDetails: { ...this.formikRef.current?.values,
    currency :selectedCurrency,
    currencySymbol: selectedSymbol
  }
  }));
  
};

handleCreateUniversityModalOpen=(open:boolean)=>{
  this.setState({openCreateUniversityModal:open})
}
getHeaderToken=async()=>{
  const token = await getStorageData("loginSuccessToken");
  const header = {
    token: token,
  };
  return header;
}
createNewUniverisy = async(name:string) => {
  this.setState({createUniversityLoading:true})
  const header = await this.getHeaderToken();
  const formData=new FormData();
  formData.append("name",name);
  const requestMessage = CreateRestApiMessage({
    header: header,
    apiUrl: `${configJSON.createUniversityApiUrl}`,
    method: "POST",
    body: formData,
  });
  this.createUniversityApiCallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
updateEducationUniversity = () => {
  console.log(this.formikRefEducation.current, "currentUpdate")
  this.formikRefEducation.current.setFieldValue(`university`, this.state.chosenCreatedUniversity);
}

handleCreateUniversityApiPayload=(response_json:any, apiRequestCallId:any)=>{
  if(apiRequestCallId===this.createUniversityApiCallId){
  const response=response_json?.data;

  if(response?.id){
      const newUniversity:IUniversityCollege={
        id:response.id,
        type:"university",
         attributes:{
          name:response.name,
          id:response.id
         }
      }
      this.setState(prev=>({
        createUniversityLoading:false,
        openCreateUniversityModal:false,
        userUniversityData:[...prev.userUniversityData,newUniversity],
        chosenCreatedUniversity: newUniversity.id
      }),() => {
        this.updateEducationUniversity();
      })
  }else{
    toast.error("Something wen't wrong!!")
    this.setState({createUniversityLoading:false,openCreateUniversityModal:false})
  }
}
}
getCreatedUniversityList = async() => {
  const header = await this.getHeaderToken();
  const requestMessage = CreateRestApiMessage({
    header: header,
    apiUrl: `${configJSON.getUniversityListApiUrl}`,
    method: "GET",
    body: null,
  });
  this.getUniversityListApiCallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleUniversityListPayload=(response:any)=>{
  this.setState({
    universityList:response?.data || []
  })
} 

checkSignUp = async() => {
  const signupFlag = await getStorageData("userSignupin");
  if (signupFlag) {
    removeStorageData("userSignupin");
    window.location.reload();
  }
}

validateCheck = (name: string, value: any) => {
  this.setState((prevState) => ({
    validateTitles: {
      ...prevState.validateTitles,
      [name]: value
    }
  }));
}

handleErrorTrigger = (name: 'jobTitle' | 'education') => {
  if (name === 'jobTitle' && this.state.validateTitles[name].trim()) {
    return true;
  } else if (name === 'education' && this.state.validateTitles[name]) {
    return true;
  } else {
    return false;
  }
}

handleFreelanceExperience = (value: string) => {
  if (value === "") {
    this.setState({
      freelanceexperience: "",
      isDisable: this.state.experiencesData.length > 0,
      error: {
        ...this.state.error,
        yearsOfExperience:
          this.state.experiencesData.length > 0
            ? "Experience should be at least 1 year since you have work experience."
            : "",
      },
    });
  } else {
    const numericValue = parseInt(value, 10);
    if (numericValue <= 60 && numericValue >= 0) {
      this.setState({
        freelanceexperience: value,
        isDisable: false,
        error: {
          ...this.state.error,
          yearsOfExperience: "",
        },
      });
      this.checkDisable(numericValue);
    } else {
      this.setState({
        isDisable: true,
        error: {
          ...this.state.error,
          yearsOfExperience: "Experience must be less than or equal to 60 years.",
        },
      });
    }
  }
};

checkDisable = (value: number) => {
  if (this.state.experiencesData.length === 0 && value <= 60 && value > 0) {
    this.setState((prevState) => ({
      error: {
        ...prevState.error,
        workExperience: "Please add work experience since your freelancing experience is not 0.",
        yearsOfExperience: "",
      },
      isDisable: true,
    }));
  } else if (value === 0 && this.state.experiencesData.length === 0) {
    this.setState((prevState) => ({
      error: {
        ...prevState.error,
        workExperience: "",
      },
    }));
  }
};
    // Customizable Area End
}
