import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { ICategoryDataModel, IEducationalQualification, IFieldOfStudy, IIndustry, IUniversityCollege} from "./types";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import {corporateFreelancerValidationSchema, sponsorvalidationSchema, studentFreelancerValidationSchema,freelancerIndiviualValidationSchema} from './validationSchema'
import { ICurrencyInfo } from "./AccountinfoController.web";
const cityDataList =require('../assets/cities.json');
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tab: boolean;
  numDetails: number;
  skill_ids: any;
  hobby_ids: any;
  uploaded_documents: any;
  basicdetail: any;
  photo: any;
  student_id: any;
  company_logo: any;
  authtoken: any;
  startYear: string;
  endYear: string;
  userrole: any;
  submitLoading: boolean;
  profileRole:{
    freelancer:boolean;
    sponsor:boolean;
    subRole:"individual"|"student"|"corporate";
  }
  countryDataList:string[];
  cityDataList:string[];
  stateDataList:string[];
  getCountryLoading:boolean;
  getCityLoading:boolean;
  getStateLoading:boolean;
  categoriesList:ICategoryDataModel[];
  skillList:ICategoryDataModel[];
  getCategoryLoading:boolean;
  skillLoading:boolean;
  loggedInUserEmail:string;
  getEducationLoading:boolean;
  educationList:IEducationalQualification[];
  fieldOfStudyList:IFieldOfStudy[];
  universityList:IUniversityCollege[];
  industryList:IIndustry[];
  freelancingExperienceList:string[];
  currencyDataList:ICurrencyInfo[];
  createUniversityLoading:boolean;
  openCreateUniversityModal:boolean;
  chosenCreatedUniversity: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ProfilesetupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  Freelancerindividual: string = "";
  getCountryDataListApiCallId:string="";
  getCityDataListApiCallId:string="";
  getStateDataListApiCallId:string="";
  getTimeZoneDataListApiCallId:string="";
  getCategoryDataListApiCallId:string="";
  getSkillDataListApiCallId:string="";
  getLoggedinUserInfoApiCallId:string="";
  getEducationApiCallId:string="";
  getUniversityListApiCallId:string="";
  getFieldOfStudyApiCallId:string="";
  getIndustryListApiCallId:string="";
  getFreelancingExperienceApiCallId:string="";
  getCurrencyListDataApiCallId:string="";
  createUniversityApiCallId:string="";
  apiGetCurrentUserCallId: string=""
  fileInput: any;
  basicDetailData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      profileRole:{
        freelancer:false,
        sponsor:false,
        subRole:"individual"
      },
      tab: true,
      numDetails: 1,
      skill_ids: [],
      hobby_ids: [],
      uploaded_documents: null,
      basicdetail: window.localStorage.getItem("Basicdetailarray"),
      photo: null,
      student_id: null,
      company_logo: null,
      authtoken: window.localStorage.getItem("loginSuccessToken"),
      startYear: "",
      endYear: "",
      userrole: null,
      submitLoading: false,
      countryDataList:[],
      cityDataList:[],
      stateDataList:[],
      getCityLoading:false,
      getCountryLoading:false,
      getStateLoading:false,
      categoriesList:[],
      skillList:[],
      getCategoryLoading:false,
      skillLoading:false,
      loggedInUserEmail:"",
      getEducationLoading:false,
      educationList:[],
      universityList:[],
      fieldOfStudyList:[],
      industryList:[],
      freelancingExperienceList:[],
      currencyDataList:[],
      createUniversityLoading:false,
      openCreateUniversityModal:false,
      chosenCreatedUniversity: "",
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.Freelancerindividual) {
      this.handleProfilePayload(response);
    }
    if(apiRequestCallId===this.getCountryDataListApiCallId){
      this.handleCountryDataListPayload(response);
    }
    if(apiRequestCallId===this.getStateDataListApiCallId){
      this.handleStateDataListPayload(response);
    }
    if(apiRequestCallId===this.getCategoryDataListApiCallId){
      this.handleCategoryDataListPayload(response);
    }
    if(apiRequestCallId===this.getSkillDataListApiCallId){
      this.handleSkillDataListPayload(response);
    }
    if(apiRequestCallId===this.getLoggedinUserInfoApiCallId){
      this.handleGetLoggedinUserPayload(response);
    }
    if(apiRequestCallId===this.getCityDataListApiCallId){
      this.handleCityDataPayload(response);
    }
    if(apiRequestCallId===this.getEducationApiCallId){
      this.handleEducationPayload(response);
    }
    if(apiRequestCallId===this.getFieldOfStudyApiCallId){
      this.handleFieldOfStudyPayload(response);
    }
    if(apiRequestCallId===this.getUniversityListApiCallId){
      this.handleUniversityListPayload(response);
    }
    if(apiRequestCallId===this.getIndustryListApiCallId){
      this.handleIndustryListPayload(response);
    }
    if(apiRequestCallId===this.getFreelancingExperienceApiCallId){
      this.handleFreelancingExperiencePayload(response);
    }
    if(apiRequestCallId===this.getCurrencyListDataApiCallId){
      this.handleCurrencyListApiPayload(response);
    }
    this.handleCreateUniversityApiPayload(response, apiRequestCallId);
    this.getcurrentuserResponse(apiRequestCallId, response)

    // Customizable Area End
  }

  // Customizable Area Start
  getcurrentuserResponse = (apiCallId: any, response: any)=> {
     if(apiCallId===this.apiGetCurrentUserCallId){
      if(response?.data?.attributes?.account_detail?.data?.attributes?.activated){
        const freelancer = response?.data?.attributes?.role_name?.toLowerCase()?.includes("freelancer")
        if (freelancer) {
          this.props.navigation.navigate("FreelancerDashboard");
        } else{
          this.props.navigation.navigate("SponsorDashboard");
        }
          
      }
    }
  }
  handleCreateUniversityApiPayload=(response_json:any, apiRequestCallId:any)=>{
    if(apiRequestCallId===this.createUniversityApiCallId){
    const response=response_json?.data;
    if(response?.id){
        const newUniversity:IUniversityCollege={
          id:response.id,
          type:"university",
           attributes:{
            name:response.name,
            id:response.id
           }
        }
        this.setState(prev=>({
          createUniversityLoading:false,
          openCreateUniversityModal:false,
          universityList:[...prev.universityList,newUniversity],
          chosenCreatedUniversity: newUniversity.id
        }))
    }else{
      toast.error("Something wen't wrong!!")
      this.setState({createUniversityLoading:false,openCreateUniversityModal:false})
    }
  }
  }
  handleCurrencyListApiPayload=(response:{data?:ICurrencyInfo[]})=>{
      this.setState({currencyDataList:response?.data || []})
  }
  handleFreelancingExperiencePayload=(response:any)=>{
      if(response?.errors){
        return;
      }
      this.setState({
        freelancingExperienceList:response?.map((item:number)=>item.toString()) ||[]
      })
  }
  handleIndustryListPayload=(response:any)=>{
    this.setState({
      industryList:response?.data || []
    })
  } 
  handleUniversityListPayload=(response:any)=>{
    this.setState({
      universityList:response?.data || []
    })
  } 
  handleFieldOfStudyPayload=(response:any)=>{
    this.setState({
      fieldOfStudyList:response?.data || []
    })
  }
   handleEducationPayload=(response:any)=>{
    this.setState({
      getEducationLoading:false,
      educationList:response?.data || []
    })
  }
  
  handleCityDataPayload=(response:any)=>{
    this.setState({
      getCityLoading:false,
      cityDataList:response?.city_names.length>0?response?.city_names:[]
    })
  }
  handleGetLoggedinUserPayload=(response:any)=>{
    this.setState({
      loggedInUserEmail:response?.email || ""
    })
  }

  //Web events
  handleCountryDataListPayload=(response:any)=>{
    this.setState({
      countryDataList:response?.countries_name || [],
      getCountryLoading:false
    })
  }
  handleStateDataListPayload=(response:any)=>{
    this.setState({
      stateDataList:response?.state_names || [],
      getStateLoading:false
    })
  }
  
  handleCategoryDataListPayload = (responseJson: any) => {
    const categories:ICategoryDataModel[]=responseJson?.categories?.length>0?responseJson?.categories?.map((item:any)=>{
       return{
         id:item?.id,
         value:item?.name,
         label:item?.name
       }
    }):[]
    this.setState({
      categoriesList:categories,
      getCategoryLoading:false
    });
  };
  handleSkillDataListPayload = (responseJson: any) => {
    const skills:ICategoryDataModel[]=responseJson?.data?.length>0?responseJson?.data?.map((item:any)=>{
      return{
        id:item?.id,
        value:item?.attributes?.name,
        label:item?.attributes?.name
      }
   }):[]
   this.setState({
    skillList:skills,
     skillLoading:false
   });
  };

  async componentDidMount() {
    this.getCurrentUser();
    this.getCountryDataList();
    this.getSkillDataList();
    this.getCategoryDataList();
    this.getLoggedinUserEmail();
    this.getEducationList();
    this.getFieldOfStudyList();
    this.getUniversityList();
    this.getIndustryList();
    this.getFreelancingExperienceList();
    this.getCurrencyList();
    const userRole=localStorage.getItem("userRole");
    if(userRole){
      this.setState({
        profileRole:JSON.parse(userRole)
      })
    }


    const convertedFile = await this.convertBlobToFile();
    this.setState({ photo: convertedFile });
  }

  errosHandler=(errors: any)=> {
    if (errors?.length) {
      if (errors[0]?.token) {
        this.handlecancle();
      } else {
        errors.forEach((item: any) => {
          const key = Object.keys(item)[0];
          if (key) {
            toast.error(item[key]?.toString());
          }
        });
      }
    }
  }
  sendLogoutMessage=()=>{
    const msg: Message = new Message(getName(MessageEnum.UserLogoutMessage));
    this.send(msg);
  }
  handleCreateUniversityModalOpen=(open:boolean)=>{
      this.setState({openCreateUniversityModal:open})
  }

  getCurrencyList=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCurrencyListingApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getCurrencyListDataApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFreelancingExperienceList = async() => {
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getFreelancingExperienceApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getFreelancingExperienceApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getIndustryList = async() => {
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getIndustryListApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getIndustryListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUniversityList = async() => {
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getUniversityListApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getUniversityListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getFieldOfStudyList = async() => {
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getFieldOfStudyApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getFieldOfStudyApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createNewUniverisy = async(name:string) => {
    this.setState({createUniversityLoading:true})
    const header=await this.getHeaderToken();
    const formData=new FormData();
    formData.append("name",name);
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.createUniversityApiUrl}`,
      method: "POST",
      body: formData,
    });
    this.createUniversityApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getEducationList = async() => {
    this.setState({getEducationLoading:true})
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getEducationApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getEducationApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getLoggedinUserEmail = async() => {
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCurrentUserApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getLoggedinUserInfoApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleProfilePayload = (response: any) => {
    this.setState({ submitLoading: false });
    if(response?.profile_exists){
      window.location.href="/";
      return;
   }
    if(response?.error){
      toast.error(response?.error)
      return;
    }
    const errors = response?.errors;
    this.errosHandler(errors);
    
    if(!response.data.attributes.account_detail.data.attributes.activated){
      this.sendResetProfileInfoMessage();
      return
    }
    if (response.data) {
      window.localStorage.setItem(
        "profileid",
        JSON.stringify(response.data?.id)
      );
      localStorage.removeItem("Basicdetailarray");
      localStorage.removeItem("studentForm");
      localStorage.removeItem("corporateForm");
      localStorage.removeItem("individualForm");
      localStorage.removeItem("sponsorForm");
      localStorage.removeItem("userRole");
      localStorage.removeItem("selectedRole");
      const {freelancer,sponsor}=this.state.profileRole;
      window.scrollTo(0,0);
      
      if (freelancer && !sponsor) {
        this.props.navigation.navigate("FreelancerDashboard");
      } else if (!freelancer && sponsor) {
        this.props.navigation.navigate("SponsorDashboard");
      } else {
        this.props.navigation.navigate("SponsorDashboard");
      }
    }
  };


  getCategoryDataList = () => {
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.getProjectCategoryApiUrl,
      body: null,
      method: "GET",
    });

    this.getCategoryDataListApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  getSkillDataList = () => {
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.getSkillListApiUrl,
      body: null,
      method: "GET",
    });

    this.getSkillDataListApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    return header;
  } 
  getHeaderToken=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };
    return header;
  }

  getCountryDataList = async() => {
    const header=await this.getHeader();
    this.setState({getCountryLoading:true})
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCountryListApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getCountryDataListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getStateDataList = async(countryName:string) => {
    const header=await this.getHeader();
    this.setState({getStateLoading:true,stateDataList:[]})
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getStateListApiUrl}?country_name=${countryName}`,
      method: "GET",
      body:null,
    });
    this.getStateDataListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCityDataList = async(stateName:string) => {
    let cityList:string[]=[];
    for(let i=0; i<cityDataList?.length; i++){
      const city=cityDataList[i];
      if(city?.state_name?.includes(stateName)){
        cityList.push(city?.name)
      }
    }
    this.setState({cityDataList:cityList,getCityLoading:false})
  };

  sendResetProfileInfoMessage=()=>{
    const msg: Message = new Message(getName(MessageEnum.ResetProfileMessage));
    this.send(msg)
  }
  convertBlobToFile = () => {
    const data = JSON.parse(this?.state?.basicdetail);
    const blobUrl = data?.photo;
    if (blobUrl) {
      return fetch(blobUrl)
        .then((response) => response.blob())
        .then((blobData) => {
          return new File([blobData], data.photoName);
        });
    }
    return null;
  };

  handleChange = (event: any, value: boolean) => {
    if (value !== this.state.tab) {
      this.setState({ tab: value });
    }
  };
  handleAddDetail = () => {
    this.setState({ numDetails: this.state.numDetails + 1 });
  };

  handleSkillSelectChange = (selectedOptions: any) => {
    this.setState({ skill_ids: selectedOptions });
  };

  handleCategorySelectChange = (selectedOptions: any) => {
    this.setState({ hobby_ids: selectedOptions });
  };

  handleFileChange = (event: unknown) => {
    const fileInput = (event as {target: {files: unknown[]}}).target?.files[0];
    this.setState({ uploaded_documents: fileInput });
  };
  handleCollegeIDUploadChange = (event: unknown) => {
    const fileInput = (event as {target: {files: unknown[]}}).target?.files[0];
    this.setState({ student_id: fileInput });
  };

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    this.setState({ company_logo: droppedFile });
  };

  setCompanyLogo = (logo: any) => {
    if (logo && logo?.size) {
      if (!logo.type.startsWith("image/")) {
        toast.error("Please select a image file");
        return;
      }
      const fileSizeInMB = logo.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        toast.error(
          "Company logo size exceeds 5MB limit,Please choose a smaller image"
        );
        return;
      }
    }
    this.setState({ company_logo: logo });
  };
  handlecompanylogoChange = (event: any) => {
    const selectedFile = event.target?.files[0];
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      toast.error("Please select a file smaller than 5MB.");
      event.target.value = null;
      return;
    }
    this.setState({ company_logo: selectedFile });
  };

  handleClick = () => {
    this.fileInput.click(); // Programmatically trigger the file input click
  };

  handleGoBack=()=>{
     this.props.navigation?.goBack();
  }

  handlecancle = () => {
    this.props.navigation.navigate("SignIn");
  };
  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  //Api
  handleStudentFormSubmitBoth = (values: any, { setErrors }: any) => {
    localStorage.setItem("studentForm", JSON.stringify(values));
    setStorageData("userSignupin",true);
    this.checkStudentFreelancer({ values, setErrors }, true);
  };
  handleStudentFormSubmitOnly = (values: any, { setErrors }: any) => {
    localStorage.setItem("studentForm", JSON.stringify(values));
    setStorageData("userSignupin",true);
    this.checkStudentFreelancer({ values, setErrors });
  };
  handleCorporateFormSubmitBoth = (values: any, { setErrors }: any) => {
    localStorage.setItem("corporateForm", JSON.stringify(values));
    setStorageData("userSignupin",true);
    this.checkCorporateFreelancer({ values, setErrors }, true);
  };
  handleCorporateFormSubmitOnly = (values: any, { setErrors }: any) => {
    localStorage.setItem("corporateForm", JSON.stringify(values));
    setStorageData("userSignupin",true);
    this.checkCorporateFreelancer({ values, setErrors });
  };
  validateSchema = (schema: any, value: any):Promise<boolean> => {
    return new Promise((resolve, reject) => {
      schema
        .validate(value)
        .then(() => {
          resolve(true);
        })
        .catch((err:any) => {
          resolve(false);
        });
    });
  };

checkValidation=async(name:string,value:any):Promise<boolean>=>{
  if(name==="sponsorForm"){
    return this.validateSchema(sponsorvalidationSchema,value)
  }else if(name==="studentForm"){
    return this.validateSchema(studentFreelancerValidationSchema,value)
  }else if(name==="corporateForm"){
    return  this.validateSchema(corporateFreelancerValidationSchema,value)
  }else if(name==="individualForm"){
    return this.validateSchema(freelancerIndiviualValidationSchema,value)
  }
  return true;
}


  checkIndividualFreelancer = async(
    { values, setErrors }: any,
    checkOtherTab: boolean = false
  ) => {
    const { uploaded_documents } = this.state;
    let error = false;
    if (uploaded_documents && uploaded_documents?.name) {
      const fileSizeInMB = uploaded_documents.size / (1024 * 1024);
      if (fileSizeInMB > 10) {
        toast.error(
          "File size exceeds by 10 MB,Please select file of 10 MB or less"
        );
        error = true;
      }
    }

    if (error) {
      return;
    }

    if (checkOtherTab) {
      const sponsorString = localStorage.getItem("sponsorForm");
      let isValid=false;
      if(sponsorString){
        isValid=await this.checkValidation("sponsorForm",JSON.parse(sponsorString))
      }
      if (isValid && sponsorString) {
        const basicInfo = this.populateBasicInfo();
        const sponsorInfo = this.populateSponcerFormData(
          JSON.parse(sponsorString)
        );
        const individualFormData = this.populateIndividualFormData(values);
        const combineData = this.combineFormDatas(basicInfo, sponsorInfo);
        const finalValue = this.combineFormDatas(
          combineData,
          individualFormData
        );
        this.submitFormInfo(finalValue);
      } else {
        toast.warn("Please enter sponsor profile information");
        this.setState({
          tab: false,
        });
      }
    } else {
      const basicInfo = this.populateBasicInfo();
      const individualFormData = this.populateIndividualFormData(values);
      const finalValue = this.combineFormDatas(basicInfo, individualFormData);
      this.submitFormInfo(finalValue);
    }
  };

  checkStudentFreelancerError=(values:any)=>{
    const { student_id, uploaded_documents } = this.state;
    let error = false;
    //check file size of the uploaded documents
    if (student_id && student_id?.name) {
      const fileSizeInMB = student_id.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        toast.error(
          "Student id image size exceeds 5MB limit,Please choose a smaller image"
        );
        error = true;
      }
    }
    if (uploaded_documents && uploaded_documents?.name) {
      const fileSizeInMB = uploaded_documents.size / (1024 * 1024);
      if (fileSizeInMB > 10) {
        toast.error(
          "File size of uploaded resume exceeds by 10 MB,Please select file of 10 MB or less"
        );
        error = true;
      }
    }
    return error;
  }

  checkStudentFreelancer = async(
    { values, setErrors }: any,
    checkOtherTab: boolean = false
  ) => {
    values.student_id = this.state.student_id;
    const error=this.checkStudentFreelancerError(values);
    if (error) {
      return;
    }

    if (checkOtherTab) {
      const studentFormString = localStorage.getItem("sponsorForm");
      let isValid=false;
      if(studentFormString){
        isValid=await this.checkValidation("sponsorForm",JSON.parse(studentFormString))
      }
      if (studentFormString && isValid) {
        const basicInfo = this.populateBasicInfo();
        const sponsorInfo = this.populateSponcerFormData(
          JSON.parse(studentFormString)
        );
        const studentFormData = this.populateStudentFreelancerFormData(values);
        const combineData = this.combineFormDatas(basicInfo, sponsorInfo);
        const finalValue = this.combineFormDatas(combineData, studentFormData);
        this.submitFormInfo(finalValue);
      } else {
        toast.warn("Please enter sponsor profile information");
        this.setState({
          tab: false,
        });
      }
    } else {
      const basicInfo = this.populateBasicInfo();
      const studentFormData = this.populateStudentFreelancerFormData(values);
      const finalValue = this.combineFormDatas(basicInfo, studentFormData);
      this.submitFormInfo(finalValue);
    }
  };
  checkCorporateFreelancer =async (
    { values, setErrors }: any,
    checkOtherTab: boolean = false
  ) => {
    const { company_logo } = this.state;
    let error = false;
    if (company_logo && company_logo?.size) {
      if (!company_logo.type.startsWith("image/")) {
        toast.error("Please select a image file");
        error = true;
      }
      const fileSizeInMB = company_logo.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        toast.error(
          "Company logo size exceeds 5MB limit,Please choose a smaller image"
        );
        error = true;
      }
    }
    if (error) {
      return;
    }
    if (checkOtherTab) {
      const corporateFormString = localStorage.getItem("sponsorForm");
      let isValid=false;
      if(corporateFormString){
        isValid=await this.checkValidation("sponsorForm",JSON.parse(corporateFormString))
      }
      if (corporateFormString && isValid) {
        const basicInfo = this.populateBasicInfo();
        const sponsorInfo = this.populateSponcerFormData(
          JSON.parse(corporateFormString)
        );
        const corporateFormData = this.populateCorporateFormData(values);
        const combineData = this.combineFormDatas(basicInfo, sponsorInfo);
        const finalValue = this.combineFormDatas(
          combineData,
          corporateFormData
        );
        this.submitFormInfo(finalValue);
      } else {
        toast.warn("Please enter sponsor profile information");
        this.setState({
          tab: false,
        });
      }
    } else {
      // this.FreelancerCorporet(values)
      const basicInfo = this.populateBasicInfo();
      const corporateFormData = this.populateCorporateFormData(values);
      const finalValue = this.combineFormDatas(basicInfo, corporateFormData);
      this.submitFormInfo(finalValue);
    }
  };

  checkKey = (key: string, value: any) => {
    if (key === "studentForm") {
      return this.populateStudentFreelancerFormData(value);
    } else if (key === "corporateForm") {
      return this.populateCorporateFormData(value);
    } else if (key === "individualForm") {
      return this.populateIndividualFormData(value);
    } else {
      return new FormData();
    }
  };

  checkSponsorProfile = async(
    { values, setErrors }: any,
    checkOtherTab: string = ""
  ) => {
    if (checkOtherTab) {
      const otherTabValueStr = localStorage.getItem(checkOtherTab);
      let isValid=false;
      if(otherTabValueStr ){
        let otherTabValueJSON = JSON.parse(otherTabValueStr)
        otherTabValueJSON.student_id = this.state.student_id
        isValid=await this.checkValidation(checkOtherTab,otherTabValueJSON)
      }
      if (otherTabValueStr && isValid) {
        const basicInfo = this.populateBasicInfo();
        const otherInfo = this.checkKey(
          checkOtherTab,
          JSON.parse(otherTabValueStr)
        );
        const sponsorFormData = this.populateSponcerFormData(values);
        const combineData = this.combineFormDatas(basicInfo, otherInfo);
        const finalValue = this.combineFormDatas(combineData, sponsorFormData);
        this.submitFormInfo(finalValue);
      } else {
        this.setState({
          tab: true,
        });
      }
    } else {
      const basicInfo = this.populateBasicInfo();
      const sponsorInfo = this.populateSponcerFormData(values);
      const finalValue = this.combineFormDatas(basicInfo, sponsorInfo);
      this.submitFormInfo(finalValue);
    }
  };

  populateBasicInfo = () => {
    
    const roleId=localStorage.getItem("selectedRole") as string;
    const formData = new FormData();
    const data = JSON.parse(this.state.basicdetail);
    this.basicDetailData = data;
    formData.append("[profile][role_id]",roleId?.trim());
    formData.append("[profile][country]", data?.country);
    formData.append("[profile][address]", data?.address);
    formData.append("profile[address_line_2]", data?.address_line_2);
    formData.append("[profile][city]", data?.city);
    formData.append("[profile][postal_code]", data?.postal_code);
    formData.append("[profile][first_name]", data?.first_name);
    formData.append("[profile][last_name]", data?.last_name);
    formData.append("[profile][gender]", data?.gender?.toLowerCase());
    formData.append("[profile][date_of_birth]", data?.date_of_birth);
    formData.append(
      "[profile][mobile_number]",
      data?.mobile_number?.toString()
    );
    formData.append("[profile][time_zone]", data?.time_zone);
    formData.append("[profile][state]", data?.state);
    if(this.state.photo){
      formData.append("[profile][photo]", this.state.photo);
    }
    const currencyInfo=this.getCurrencyInfo(data?.currency);
    formData.append("profile[currency]",currencyInfo.currencyName || "");
    formData.append("profile[currency_symbol]",currencyInfo.currencySymbol || "");
    return formData;
  };
  getCurrencyInfo=(currencyId:string)=>{
      const selectedCurrency=this.state.currencyDataList.find((item)=>item.id==currencyId);
      return{
          currencyName:selectedCurrency?.attributes?.currency,
          currencySymbol:selectedCurrency?.attributes?.currency_symbol
      }
  }
  populateIndividualFormData = (values: any) => {

    const {uploaded_documents } = this.state;
    const formData = new FormData();
    values?.skill_ids?.forEach((skill:any)=>{
      formData.append("profile[skill_id][]",skill?.id);
    })
    values?.hobby_ids?.forEach((hobby:any)=>{
      formData.append("profile[category_of_interest_id][]",hobby?.id)
    })

    if(uploaded_documents){
      formData.append("profile[resumes][]",uploaded_documents)
    }
    formData.append("profile[certification][]",values?.certification)
    formData.append("profile[experience_in_years]",values?.experience_in_years)

    values?.educational_qualifications?.forEach((education:any)=>{
      formData.append("profile[educational_qualifications_attributes][][field_study_id]",education?.field);
      formData.append("profile[educational_qualifications_attributes][][education_id]",education?.education);
      formData.append("profile[educational_qualifications_attributes][][university_college_id]",education?.college);
    })

    if (values?.experience_in_years > 0 && values?.career_experiences) {
      values.career_experiences.forEach((career:any) => {
        formData.append("profile[career_experiences_attributes][][job_title]", career?.jobTitle)
        formData.append("profile[career_experiences_attributes][][employer_name]", career?.employerName)
        formData.append("profile[career_experiences_attributes][][country]", career?.country)
        formData.append("profile[career_experiences_attributes][][state]", career?.state)
        formData.append("profile[career_experiences_attributes][][city]", career?.city)
        formData.append("profile[career_experiences_attributes][][description]", career?.jobDescription)
      })
    }
    return formData;
  };
  populateStudentFreelancerFormData = (values: any) => {
    const { student_id, uploaded_documents } = this.state;
    const formData = new FormData();
    values?.skill_ids?.forEach((skill:any)=>{
      formData.append("profile[skill_id][]",skill?.id);
    })
    values?.hobby_ids?.forEach((hobby:any)=>{
      formData.append("profile[category_of_interest_id][]",hobby?.id)
    })
    if(uploaded_documents){
      formData.append("profile[resumes][]",uploaded_documents)
    }
     if(student_id){
      formData.append("profile[uploaded_documents][]",student_id)
    }
    formData.append("profile[certification][]",values?.certification)
    formData.append("profile[experience_in_years]",values?.experience_in_years)

    values?.educational_qualifications?.forEach((education:any)=>{
      formData.append("profile[educational_qualifications_attributes][][field_study_id]",education?.field);
      formData.append("profile[educational_qualifications_attributes][][education_id]",education?.education);
      formData.append("profile[educational_qualifications_attributes][][university_college_id]",education?.college);
      formData.append("profile[educational_qualifications_attributes][][currently_studying_here]",education?.current_study?"true":"false");
    })
    if (values?.experience_in_years > 0 && values?.career_experiences) {
      values.career_experiences.forEach((career:any) => {
        formData.append("profile[career_experiences_attributes][][job_title]", career?.jobTitle)
        formData.append("profile[career_experiences_attributes][][employer_name]", career?.employerName)
        formData.append("profile[career_experiences_attributes][][country]", career?.country)
        formData.append("profile[career_experiences_attributes][][state]", career?.state)
        formData.append("profile[career_experiences_attributes][][city]", career?.city)
        formData.append("profile[career_experiences_attributes][][description]", career?.jobDescription)
      })
    }

    return formData;
  };
  populateCorporateFormData = (values: any) => {
    const formData = new FormData();
    //Start here
    values?.skill_ids?.forEach((skill:any)=>{
      formData.append("profile[skill_id][]",skill?.id);
    })
    values?.hobby_ids?.forEach((hobby:any)=>{
      formData.append("secondary_company[category_of_interests][]",hobby?.id);
    })
    formData.append("secondary_company[company_name]",values?.company_name);
    formData.append("secondary_company[year_of_registration]",values?.year_Regis);
    formData.append("secondary_company[registration_number]",values?.companyReg_no);
    formData.append("secondary_company[company_website]",values?.company_website);
    formData.append("secondary_company[address_line_1]",this.basicDetailData?.address);
    formData.append("secondary_company[address_line_2]",this.basicDetailData?.address_line_2);
    formData.append("secondary_company[country]",values?.country);
    formData.append("secondary_company[state]",values?.state);
    formData.append("secondary_company[city]",values?.city);
    formData.append("secondary_company[postal_code]",values?.postal_code);
    formData.append("secondary_company[company_profile]",values?.company_profile);
    formData.append("primary_company[company_profile]",values?.company_profile);
    formData.append("secondary_company[awards_won_by_company][]",values?.award_certificate);
    formData.append("secondary_company[freelancing_experience]",values?.experience_in_years);
    formData.append("profile[experience_in_years]",values?.experience_in_years)
    formData.append("secondary_company[other_industry]",values?.type_of_industry);
    formData.append("secondary_company[number_of_employee]",values?.number_of_employees);
    formData.append("secondary_company[vat_id]",values?.tax_id);
    formData.append("secondary_company[company_overview]",values?.company_overview);
    values?.certifications?.forEach((cert:string)=>{
      formData.append("secondary_company[certification][]",cert);
    })
    const { company_logo } = this.state;
    if(company_logo?.name){
      formData.append("secondary_company[logo]",company_logo)
    }
    //End here
    return formData;
  };
  populateSponcerFormData = (values: any) => {
    const formData = new FormData();
    formData.append("primary_company[company_name]",values?.company_name);
    formData.append("primary_company[company_website]",values?.website);
    formData.append("primary_company[other_industry]",values?.industry);
    formData.append("primary_company[number_of_employee]",values?.number_of_employee);
    formData.append("primary_company[vat_id]",values?.vat_id);
    formData.append("primary_company[company_contact]",values?.mobile_number);
    formData.append("[profile][industry]", values?.industry);
    formData.append("secondary_company[company_size]", values?.number_of_employee);
    formData.append("primary_company[company_size]", values?.number_of_employee);
    formData.append("primary_company[address_line_1]",values?.address1);
    values?.hobby_ids?.forEach((hobby:any)=>{
      formData.append("primary_company[category_of_interests][]",hobby?.id);
    })
    return formData;
  };
  combineFormDatas = (formData1: any, formData2: any) => {
    const combinedFormData = new FormData();
    if (formData1?.entries) {
      for (let [key, value] of formData1?.entries()) {
        combinedFormData.append(key, value);
      }
    }
    if (formData2?.entries) {
      for (let [key, value] of formData2?.entries()) {
        combinedFormData.append(key, value);
      }
    }
    return combinedFormData;
  };

  submitFormInfo = (formData: any) => {
    setStorageData("userSignupin",true);
    this.setState({ submitLoading: true });
    const header = {
      token: this.state.authtoken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.Freelancerindividual = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCurrentUser = async () => {
    const userToken = localStorage.getItem("loginSuccessToken");
    const header = {
      "Content-Type": configJSON.jsonContentType,
      token: userToken,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getCurrentUserApiUrl}`,
      method: "GET",
      body: null,
    });
    this.apiGetCurrentUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleStartYearChange = (event: { target: { value: any } }) => {
    const selectedDate = event.target.value;
    this.setState({ startYear: selectedDate });

    if (this.state.endYear && selectedDate > this.state.endYear) {
      this.setState({ endYear: "" });
    }
  };

  handleEndYearChange = (event: { target: { value: any } }) => {
    const selectedDate = event.target.value;
    this.setState({ endYear: selectedDate });

    if (selectedDate <= this.state.startYear) {
      this.setState({ endYear: "" });
    }
  };
  // Customizable Area End
}
