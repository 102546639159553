Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "joblisting";
exports.labelBodyText = "joblisting Body";
exports.modalheading = "Filters & Sort";

exports.btnExampleTitle = "CLICK ME";
exports.getProfileIdEndpoint = "bx_block_profile/profiles/null";
exports.getskills = "bx_block_profile/search_algorithms/fetch_skills";
exports.getlanguage = "bx_block_profile/search_algorithms/fetch_language";
exports.getcategory = "bx_block_profile/search_algorithms/fetch_category";
exports.getspeciality = "bx_block_profile/search_algorithms/fetch_specialist";
exports.getAllBidsEndPoint = "bx_block_auctionbidding/bids/get_sponsor_bids_algo";
exports.favouriteBidEndPoint = "/bx_block_favourites/favourites";
exports.biddingScope = "/bx_block_auctionbidding/bids/bidder_scope";

exports.httpGetMethod = "GET";
exports.placeHolderNameTxt = "Search Project Title or Company";
exports.filterTxt = "Filter";
exports.deleteProjectApiEndPoint="bx_block_profile/projects";
exports.httpDeleteMethod = 'DELETE'
exports.getSingleProjectInfoApiUrl="bx_block_profile/projects"
exports.createConversationApiCallId="bx_block_chat/chats"
exports.projectURL="https://lancerscapefinal-225287-react-native.b225287.dev.eastus.az.svc.builder.cafe/BidProjectDetails"
exports.facebookURL="https://www.facebook.com/sharer.php?u="
// Customizable Area End
