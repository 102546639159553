import React from "react";
// Customizable Area Start
import {
    Box,
    withStyles,
    Container,
    Typography,
    Button,
    Grid,
    Card,
    InputAdornment,
    TextField
} from "@material-ui/core";
import FreelancerInvitationDetailsController ,{Props,configJSON} from './FreelancerInvitationDetailsController.web';
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import ProjectCard from "../../../components/src/ProjectCard.web";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CircularProgress from "@material-ui/core/CircularProgress";
import AboutSponsor from '../../../components/src/my-bid/AboutSponsor.web';
import ActionModal from "../../../components/src/utill/ActionModal.web";


const StyledTextField = withStyles({
    root: {
      maxWidth: '100%',
      fontSize: '18',
      fontFamily: 'Inter',
      fontWeight:600,
      color:'#000',
      "& .MuiFilledInput-root:hover": {
        backgroundColor: "transparent",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        }
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "transparent",
      },
      "& input::placeholder": {
        fontFamily: 'Inter',
        fontSize: '14',
        fontWeight: 400,
        color: '#8083a3'
      },
      "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "transparent",
      },
      "& label": {
        color: 'rgb(128, 131, 163)',
      },
      "& textarea::placeholder": {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        color: '#8083a3',
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#8083A"
    }
    }
  })(TextField);
// Customizable Area End

export class FreelancerInvitationDetails extends FreelancerInvitationDetailsController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
      renderBidForm=()=>{

        const {projectInfo,bidErrors,bidInput,currentUser}=this.state;
        const {classes}=this.props;
        const isNonBidProject=projectInfo?.attributes?.bid_status===1;
        const isFixedPrice =projectInfo?.attributes?.budget_type==="fixed_price";
        const currency=currentUser?.attributes?.currency_symbol;
         return(
            <Card data-testid="bidBlock" className={classes.bidCard} elevation={0}>
            <Grid container spacing={3}>
            {
            isNonBidProject?null:
            <Grid item xs={12} md={4} >
                <StyledTextField
                data-testid="bidAmount"
                label={`Quote Amount* ${isFixedPrice?'':'(per hour)'}`}
                placeholder={configJSON.amountPlaceholder}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                type="number"
                name="amount"
                value={bidInput.amount}
                onChange={this.handleBidInputChange}
                error={Boolean(bidErrors.amount)}
                helperText={bidErrors.amount}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Typography className={classes.dollarSign}>{currency}</Typography>
                    </InputAdornment>
                    ),
                    style: {
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#000',
                    },
                }}
                />
            </Grid>
            }
            <Grid item xs={12} md={isNonBidProject?6:4}>
                <StyledTextField
                data-testid="startDate"
                label="Start Date*"
                placeholder="Select Start Date"
                inputProps={{
                    style: {
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#000',
                    },
                    min:this.getCurrentDate(),
                }}
                fullWidth
                type={"date"}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                name="startDate"
                value={bidInput.startDate}
                onChange={this.handleBidInputChange}
                error={Boolean(bidErrors.startDate)}
                helperText={bidErrors.startDate}
                />
            </Grid>
            <Grid item xs={12} md={isNonBidProject?6:4}>
                <StyledTextField
                data-testid="endDate"
                label="End Date*"
                placeholder="Select End Date"
                fullWidth
                type={"date"}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min:this.getCurrentDate(),
                    style: {
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#000',
                    }
                }}
                name="endDate"
                value={bidInput.endDate}
                onChange={this.handleBidInputChange}
                error={Boolean(bidErrors.endDate)}
                helperText={bidErrors.endDate}
                />
            </Grid>
            </Grid>
            <StyledTextField
            data-testid="proposal"
            label="Proposal*"
            placeholder={configJSON.proposalPlaceholder}
            fullWidth
            inputProps={{
                style: { height: "150px",
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 600,
                color: '#000',    
            },
            }}
            multiline={true}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            name="description"
            value={bidInput.description}
            onChange={this.handleBidInputChange}
            error={Boolean(bidErrors.description)}
            helperText={bidErrors.description}
            />
            <br />
            <br />
            <Grid container justifyContent="space-between">
                <Grid item>

                </Grid>
            <Grid item>
               
            </Grid>
            </Grid>
             </Card>
         )
      }
    renderInvitationActions=()=>{
        const {classes}=this.props;
        const {invitationInfo}=this.state;
        const invitationStatus=invitationInfo?.attributes?.status;
        return(
            <Box className={classes?.action_container}>
            {
                invitationStatus==="sent"&&(
                    <>
                       
                        <Button data-testid="rejectButton" onClick={this.handleOpenRejectInviteModal} className={classes.negative_button}>
                            Reject
                        </Button>
                        {
                            !this.state.openAcceptInviteRadio && (
                                <Button data-testid="acceptButton" onClick={this.handleOpenAcceptInviteModal} className={classes.positive_button}>
                                    Accept
                                </Button>
                            )
                        }
                        {
                            this.state.openAcceptInviteRadio && (
                                <>
                                     <Button data-testid="cancelButton" onClick={this.handleOpenAcceptInviteModal} className={classes.back_button}>
                                        Cancel
                                    </Button>
                                     <Button
                                         data-testid="continueBtn" 
                                         className={classes.positive_button} 
                                         variant="contained"
                                         onClick={this.handleSubmit}
                                         >
                                        {
                                            this.state.acceptLoading?<CircularProgress 
                                            style={{padding: "0px 10px 0px 10px"}}
                                            size={20}/>:"Continue"
                                        }
                                     </Button>
                                </>
                            )
                        }
                    </>
                )
            }
            {
                invitationStatus==="decline" && (
                    <Button className={classes.negative_button}>
                        Declined
                    </Button>
                )
            }
            {
                invitationStatus==="rejected" && (
                    <Button data-testid="rejected" className={classes.negative_button}>
                        Rejected
                    </Button>
                )
            }
            {
                invitationStatus==="accepted" && (
                    <Button data-testid="accepted" className={classes.positive_button}>
                        Accepted
                    </Button>
                )
            }
        </Box>
        )
    }
       // Customizable Area End
    render() {
        // Customizable Area Start
        const {projectInfo,getProjectLoading,getInvitationLoading,invitationInfo}=this.state;
        const {classes}=this.props;
        const invitationStatus=invitationInfo?.attributes?.status;
        // Customizable Area End
        return (

            // Customizable Area Start
             <Box>
                <FreelancerHeader navigation={this.props.navigation}/>
                <Container>
                <Box className={classes.row}>
                        <Button data-testid="backButton" onClick={this.handleBack} className={classes.back_button} startIcon={<KeyboardBackspaceIcon/>}>
                            Back
                        </Button>
                        {
                            getProjectLoading?<CircularProgress size={24} color="primary"/>:(
                            <Typography component={"h1"} variant="h4" className={classes.page_heading}>
                                Invitation Details
                            </Typography>
                            )
                        }
                       
                    </Box>
                    <br/>
                {
                    projectInfo !==null && !getProjectLoading? 
                    <ProjectCard 
                     card={projectInfo} 
                     showActionBar={false}
                     fav={()=>{}}/>:<Typography className={classes.no_project_found}>No Project Found</Typography>
                }
                <Box className={classes.proposal_container}>
                    <Box className={classes.invitation_header}>
                             <Typography component={"h1"} variant="h5" className={classes.page_heading}>
                                Invitation Details
                            </Typography>
                    </Box>
                    <Box style={{padding:"20px"}}>
                        <Typography component={"h1"} variant="h6" className={classes.page_heading}>
                            Invitation Letter
                        </Typography>
                        {
                            getInvitationLoading&&(
                                    <Box style={{display:"flex",alignItems:"center",justifyContent:"center",margin:"0px 20px"}}>
                                        <CircularProgress size={24} color="primary"/>
                                    </Box>
                                )
                        }
                        <Typography>
                            {invitationInfo?.attributes?.message}
                        </Typography>
                        {
                            invitationStatus==="decline" && (
                                <Box data-testid="declinedBlock" style={{marginTop:"20px"}}>
                                        <Typography component={"h1"} variant="h6" className={classes.page_heading}>
                                           Declined Reason
                                        </Typography>
                                        <br/>
                                        <Typography>
                                            {invitationInfo?.attributes?.decline_reason}
                                        </Typography>
                                </Box>
                            )
                        }
                        {
                            this.state.openAcceptInviteRadio && (

                                this.renderBidForm()
                            )
                        }
                        {this.renderInvitationActions()}
                    </Box>
                </Box>
                
                 <AboutSponsor 
                    name={projectInfo?.attributes?.sponsor_profile?.full_name as string}
                    city={projectInfo?.attributes?.sponsor_profile?.city}
                    country={projectInfo?.attributes?.sponsor_profile?.country}
                    rating={projectInfo?.attributes?.sponsor_profile?.rating || 0}
                    totalRatingCount={projectInfo?.attributes?.sponsor_profile?.total_rating || 0}
                    id = {projectInfo?.attributes?.sponsor_profile?.id}
                    profileImage={projectInfo?.attributes?.profile_image}
                    />
                </Container>
                <ActionModal
                    key="reject_invite_modal"
                    open={this.state.rejectInviteModal}
                    setOpen={this.handleCloseRejectInviteModal}
                    message={`Are you sure you want to reject this invitation?`}
                    title="Reject Invitation"
                    positiveButtonText="Yes"
                    actionHandler={this.rejectInvitations}
                    actionLoading={this.state.rejectInviteLoading}
                    />  
                <ActionModal
                    key="accept_invite_modal"
                    open={this.state.openAcceptInviteModal}
                    setOpen={this.handleCloseAcceptInviteModal}
                    message={`Are you sure you want to accept this invitation?`}
                    title="Accept Invitation"
                    positiveButtonText="Yes"
                    actionHandler={this.acceptInvitationDirectly}
                    actionLoading={this.state.acceptLoading}
                 />  
             </Box>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = () => ({
    row:{
        display:"flex",
        gap:"10px",
        alignItems:"center",
    } as const,
    page_heading:{
        fontWeight:"bolder",
        '@media (max-width: 640px)':{
            fontSize: "18px",
        },
        '@media (max-width: 480px)':{
            fontSize: "14px",
        }
    } as const,
    back_button:{
        background:"#f2f2f2",
        borderRadius:"20px",
        padding:"10px 20px",
        textTransform:"capitalize",
    } as const,  
    positive_button:{
        background:"#ec952d",
        borderRadius:"25px",
        padding:"10px 20px",
        textTransform:"capitalize",
        color:"white",
        "&:hover":{
            background:"#ec952d"
        }
    } as const,  
    negative_button:{
        background:"red",
        borderRadius:"25px",
        padding:"10px 20px",
        textTransform:"capitalize",
        color:"white",
        "&:hover":{
            background:"red"
        }
    } as const, 
    proposal_container:{
        border:"1px solid rgba(0,0,0,.1)",
        borderRadius:"20px"
    },
    invitation_header:{
        padding:"20px",
        background:"#F9F5FC",
        borderBottom:"1px solid rgba(0,0,0,.1)",
        borderRadius:"20px 20px 0px 0px"
    },
    action_container:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        gap:"20px"
    },
    bidCard: {
        borderTop: "1px solid rgb(228, 230, 232)",
        padding: "30px",
        marginTop:"20px"
      },
    dollarSign:{
        fontFamily: 'Inter',
        fontSize: '14px', 
        fontWeight: 600,
        color:'#000'
      },
      no_project_found: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }
});

export default withStyles(styles)(FreelancerInvitationDetails);

// Customizable Area End