import React from "react";

// Customizable Area Start
import {
  InputLabel,
  TextField,
  Button,
  MenuItem,
  Grid,
  Divider,
 } from "@material-ui/core";

import {ThemeProvider, withStyles, createTheme } from "@material-ui/core/styles";

// Customizable Area End
import PhoneInput from 'react-phone-input-2'

// Customizable Area Start
export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
       display:'flex',
       alignItems:"center"
      },
      selectMenu: {
        minHeight: '36px',
        borderBottom: 'none',
      }, 
      },
      MuiListItem: {
        root: {
          fontFamily: "Inter",
          fontWeight:400,
          fontSize: "12px",
          color: "#8083a3",
        },
        },
MuiInput: {
  underline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    position: 'relative',
    '&:before': {
      position: 'none',
      transition: 'none',
      border:'none',
      content:'none'
    },
  },
},


MuiInputBase: {
  input: {
    fontFamily: "Inter",
    fontWeight:400,
    fontSize: "14px",
    color: "#8083A3",
},
},
}
});
const StylTextField = withStyles ({
  root: {
    maxWidth: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
     '& .MuiInput-input': {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      color: "#8083A3",
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
 
    },
    "& .MuiInput-root:hover": {
      backgroundColor: 'transparent',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      },
    },
    "& input::placeholder": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      color: "#8083A3",
    },

  },
})(TextField);
// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
} from "./Customisableuserprofiles2Controller";

export default class SposorCompanyDetails extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetSpaceBelow = (id: string, length:number) => {
    const selectRect = document.getElementById(id || "")?.getBoundingClientRect()
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const spaceBelow = windowHeight - (selectRect?.bottom || 0)
    const dataLength = length
    const maxItems = 5
    const lengthToUse = (dataLength >= maxItems ? maxItems : dataLength)
    return(spaceBelow < ((lengthToUse/2) *100))
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <form  className={classes.mainContainer} onSubmit={(event)=>this.handleUpdateCompanyDetails(event)} data-testid="saveCompanyDetails">
          <Grid container spacing={2}>
            <Grid item  xs={12} sm={6}>
              <InputLabel className={classes.label}>
                {configJSON.companyNameLabel} 
              </InputLabel>
              <StylTextField
                InputProps={{
                  className: classes.detailsInputField,
                }}
                placeholder={configJSON.companyNamePlaceHolderTxt}
                fullWidth
                onChange={(event) =>
                  this.setState({ companyName: event.target.value })
                }
                value={this.state.companyName}
                data-testid="companyName"
                required
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <InputLabel className={classes.label}>
                {configJSON.companyWebsiteTxt}
              </InputLabel>
              <StylTextField
                InputProps={{
                  className: classes.detailsInputField,
                }}
                placeholder={configJSON.compoanyWebsitePlaceholder}
                fullWidth
                onChange={(event) =>
                  this.setState({ companyWebsite: event.target.value })
                }
                value={this.state.companyWebsite}
                data-testid="companyWebsite"
                required
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <InputLabel className={classes.label}>
                {configJSON.companyContactTxt}
              </InputLabel>
                
               <PhoneInput
              data-testid="companyContact"
              inputStyle={{
              width:"100%",
              border:"none",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "14px",
              color: "#8083A3",
              height:'50px',
              }}
               
               countryCodeEditable={false}
               country={this.state.companyCountryCode}
         
                onChange={(value, countryObj, e, formattedValue) =>
                  this.handlePhoneContact(value, countryObj, e, formattedValue)
                }
                value={this.state.companyContact}
                placeholder="Enter your phone number"
           
              />
               <Divider 
               style={styles.phoneBorderStyle}>
               </Divider>

            </Grid>

            <Grid item  xs={12} sm={6}>
              <InputLabel className={classes.label}>
                {configJSON.companyAddressTxt}
              </InputLabel>
              <StylTextField
                InputProps={{
                  className: classes.detailsInputField,
                }}
                placeholder={configJSON.companyAddressPlaceHolderTxt}
                multiline
                fullWidth
                onChange={(event) =>
                  this.setState({ companyAddress: event.target.value })
                }
                value={this.state.companyAddress}
                data-testid="companyAddress"
                required
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <InputLabel className={classes.label}>
                {configJSON.IndustryLabelTxt}
              </InputLabel>
              <StylTextField
              select
              SelectProps={{
                MenuProps:{
                  PaperProps: {
                    style:{
                      maxHeight:"250px",
                    }
                  },
                  anchorOrigin: {
                    vertical: this.handleGetSpaceBelow("companyType", this.state.industryname.length || 1) ? "top" : "bottom",
                    horizontal: "center"
                  },
                  transformOrigin: {
                    vertical: this.handleGetSpaceBelow("companyType", this.state.industryname.length || 1) ? "bottom" : "top",
                    horizontal: "center"
                  },
                  getContentAnchorEl:null
                }
              }}
                className={classes.detailsInputField2}
                fullWidth
                value={this.state.industryType}
                onChange={(event) => this.setState({ industryType: event.target.value as string })}
                data-testid="companyType"
                id="companyType"
                required
              >
                <MenuItem style={styles.menuFirstItemstyle} value={configJSON.menuDefaultTxt}> 
                  <span style={styles.industryMenuStyle} > 
                  {configJSON.companyIndustryPlaceHolderTxt}
                  </span>
               </MenuItem>

              {this.state.industryname.map((industry: any, index: number) => (
                <MenuItem key={index} value={industry.attributes.industry_name} data-testid={`companyType${index}`}>
                  {industry.attributes.industry_name}
                </MenuItem>
              ))}
            </StylTextField>
            </Grid>
            <Grid item  xs={12} sm={6}>
              <InputLabel className={classes.label}>
                {configJSON.vatIdTxt}
              </InputLabel>
              <StylTextField
                InputProps={{
                  className: classes.detailsInputField,
                }}
                required
                placeholder={configJSON.vatIDTxt}
                fullWidth
                onChange={(event) =>
                  this.setState({ vatNumber: event.target.value })
                }
                onKeyPress={this.onKeyPressAvoidNumbers}
                value={this.state.vatNumber}
                data-testid="vatnumber"
              />
            </Grid>
            <Grid item  xs={12} sm={6}>
              <InputLabel className={classes.label}>
                {configJSON.numberOfEmployeesTxt}
              </InputLabel>
              <StylTextField
                InputProps={{
                  className: classes.detailsInputField,
                }}
                required
                placeholder={configJSON.numberOfEmployeesPlaceHolderTxt}
                fullWidth
                onChange={(event) =>
                  this.setState({ companySize: event.target.value as string })
                }
                onKeyPress={this.onKeyPressAvoidNumbers}
                value={this.state.companySize}
                data-testid="companySize"
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button className={classes.cancelButton} 
              onClick={this.resetForm} 
              data-testid="cancelCompanyDetails">
                {configJSON.cancelBtnTxt}
            </Button>
            <Button
              className={
                classes.saveButton
              }
              type="submit"
            >
              {configJSON.saveBtnTxt}
            </Button>
          </div>
        </form>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = ({
  mainContainer: {
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    padding: 20,
  },
  detailsInputField: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083A3",
    "&::placeholder": {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "14px",
      color: "#8083A3",
    },
  },
  detailsInputField2:{
    fontFamily: "Inter",
    height:"49px",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083A3",
    "&::placeholder": {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "14px",
      color: "#8083A3",
    },
  },
  label: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083A3",
  },
  saveButton: {
    minWidth: '140px',
    borderRadius: '23px',
    background: "#00A7A2",
    padding: '9px 30px',
    color: '#FFFFFF',
    fontSize: '14px',
    margin: '0 15px',
    textTransform:'none' as const,
    "&:hover" : {
      color : "#FFFFFF",
    background: "#00A7A2",
    },
    "@media(max-width: 412px)": {
      margin: 0,
      width: "100%"
    }
  },
  cancelButton: {
    minWidth: '140px',
    borderRadius: '23px',
    background: "#f2f2f2",
    padding: '9px 30px',
    color: '#000',
    fontSize: '14px',
    margin: '0 15px',
    textTransform:'none' as const,
    "&:hover" : {
      color : "#000",
    background: "#f2f2f2",
    },
    "@media(max-width: 412px)": {
      margin: 0,
      width: "100%"
    }
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    "@media(max-width: 412px)": {
      gap: "10px",
      flexWrap: "wrap" as const
    },
  },

  snackBarStyle: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    color: "#FFFFFF",
    background: "#16A700",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.25098)",
    borderRadius: "32px",
  },
  notSponsor: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    color: "#8083A3",
  },
  disabled: {
    "& .MuiButton-label": {
      background: "#8083A3",
      borderRadius: "23px",
      fonFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      color: "#222222",
    },
  },
  industryMenuStyle:{ 
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
  },
  menuFirstItemstyle:{
     display: "none" 
  },
  phoneBorderStyle:{
    width:'100%', 
    height:'2px',
     color:'rgba(0, 0, 0, 0.1)'
    }
});

export const SposorCompanyDetailsStyles = withStyles(styles)(
  SposorCompanyDetails
);
// Customizable Area End
