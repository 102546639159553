import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import { lancerlogo } from "./assets";
// Customizable Area End

// Customizable Area Start
import {Box } from "@material-ui/core";
// Customizable Area End

import ProfilesetupController, { Props } from "./ProfilesetupController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import StudentForm from "../../../components/src/profile-setup/StudentForm.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";
import CreateUniversityModal from "../../../components/src/utill/CreateUniversityModal.web";

export default class FreelancerProfileCorporate extends ProfilesetupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { uploaded_documents, student_id, skill_ids, hobby_ids, startYear, endYear } = this.state
    return (
      // Customizable Area Start
      <div className="main-wrapper student_freelancer">
        <ProfileSetupHeader
          email={this.state.loggedInUserEmail}
          onLogout={this.sendLogoutMessage}
        />
        <div className="site-wrapper student_freelancer">
          <div className="setup-account-block student_freelancer">
            <div className="inner-block student_freelancer">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper
              activeBasicDetails={true}
              activeProfileSetup={true}
            />
          </div>

          <div className="choose-profile-block student_freelancer">
            <div className="profile-heading student_freelancer">
              <h6>Fill in the details(s) selected by you</h6>
            </div>
            <div className="profile-heading student_freelancer">
              <b>Freelancer Profile (Student)</b>
            </div>
            <Box sx={{padding:{xs:2,sm:5}}}>
              <div className="form-block">
                    <StudentForm
                          submitHandler={this.handleStudentFormSubmitOnly}
                          cancelHandler={ this.handleGoBack}
                          handleStudentIdChange={ this.handleCollegeIDUploadChange}
                          student_id={student_id}
                          freelancingExperienceList={this.state.freelancingExperienceList}
                          uploaded_documents={uploaded_documents}
                          handleUploadDocumentChange={this.handleFileChange}
                          submitLoading={this.state.submitLoading}
                          countryList={this.state.countryDataList}
                          countryLoading={this.state.getCountryLoading}
                          getCityList={this.getCityDataList}
                          cityList={this.state.cityDataList}
                          cityLoading={this.state.getCityLoading}
                          categories={this.state.categoriesList}
                          skills={this.state.skillList}
                          educationList={this.state.educationList}
                          fieldOfStudyList={this.state.fieldOfStudyList}
                          universityList={this.state.universityList}
                          getStateList={this.getStateDataList}
                          getStateLoading={this.state.getStateLoading}
                          stateList={this.state.stateDataList}
                          handleOpenUniversityModal={this.handleCreateUniversityModalOpen}
                          newlyCreatedUniversity={this.state.chosenCreatedUniversity}
                      />
              </div>
            </Box>
          </div>
        </div>
        <CreateUniversityModal
            open={this.state.openCreateUniversityModal}
            setOpen={this.handleCreateUniversityModalOpen}
            createUniversityLoading={this.state.createUniversityLoading}
            onCreateUniversity={this.createNewUniverisy}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End