import React from "react";

// Customizable Area Start
import { Box, Container,Menu,MenuItem } from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChatMainContent from "../../../components/src/chat/ChatMainContent.web";
import ChatInput from "../../../components/src/chat/ChatInput.web";
import ConversationList from "../../../components/src/chat/ConversationList.web";
import ActionModal from "../../../components/src/utill/ActionModal.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import { avatarImg } from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditChatModal from "../../../components/src/chat/EditChat.web";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
const drawerWidth = 300;
const headerHeight = 80;

// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderParticipantContent(participant: { full_name: string; ticket_description: any; }) {
    const isAdmin = participant?.full_name.toLowerCase() === "admin";
    return {
      label: isAdmin ? "Ticket Description :" : "Project :",
      value: isAdmin 
        ? participant?.ticket_description
        : this.state.selectedConversation?.attributes?.project_name
    };
  }

  renderConversationContent(getConversationLoading: any, props: any) {
    if (getConversationLoading) {
      return (
        <Box style={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
          <CircularProgress size={24} />
        </Box>
      );
    }
    return <ConversationList {...props} />;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const classes = this.props.classes;

    const container =
      window !== undefined ? () => window.document.body : undefined;

    const {
      messageList,
      selectedConversation,
      deleteMessage,
      deleteMessageModal,
      currentUser,
      getConversationLoading
    } = this.state;
    const isLoggedinUserSponsor=currentUser?.currentState?.toLowerCase()?.includes("sponsor");
    const userId=currentUser?.attributes?.account_detail?.data?.id;
    const color=isLoggedinUserSponsor?"#00A7A2":"";
    const conversationList=this.getFilteredConversationList();
    const participant = selectedConversation?.attributes?.participants[0];
    const { label = "", value = "" } = participant 
      ? this.renderParticipantContent(participant) 
      : { label: "", value: "" };

    const conversationProps = {
      conversationList,
      selectedConversation,
      handleSelectConversation: this.handleSelectConversation,
      searchText: this.state.searchText,
      setSearchText: this.handleSearchText,
      color,
      isSponsor: isLoggedinUserSponsor
    };
    return (
      <>
      {
        isLoggedinUserSponsor?(
          <Sponserheader navigation={this.props.navigation}/>
        ):(
          <FreelancerHeader route={"chat"} navigation={this.props.navigation}/>
        )
      }
      <Container>
      <Box className={classes.chat_main_container}>
        <CssBaseline />
        {
          selectedConversation && (
            <AppBar position="relative" className={classes.appBar}>
            <Box className={classes.app_bar_left}>
              <IconButton
                data-testid="drawerbutton"
                onClick={this.handleDrawerToogle}
                className={classes.menuButton}
              >
                <MenuIcon htmlColor="black" />
              </IconButton>
              <Box className={classes.app_bar_user_container}>
                <Box className={classes.app_bar_user_img_container}>
                  {
                    selectedConversation?.attributes?.participants[0]?.profile_image?.url?(
                      <img
                        className={classes.app_bar_user_img}
                        src={selectedConversation?.attributes?.participants[0]?.profile_image?.url || avatarImg}
                        alt={"User Image"}
                      />
                    ):(
                      <AccountCircleIcon className={classes.app_bar_user_img}/>
                    )
                  }
                  {
                    selectedConversation?.attributes?.participants[0]?.availability && (
                      <div className={classes.app_bar_user_active} />
                    )
                  }
                </Box>
                <Box className={classes.app_bar_user_details}>
                  <Typography
                    className={classes.app_bar_user_name}
                    component={"h3"}
                  >
                    {selectedConversation?.attributes?.participants[0]?.full_name}
                  </Typography>
                  <Typography
                    className={classes.app_bar_chat_description}
                    component="p"
                  >
                    <Typography className={classes.header_description}>
                    <Box className={classes.parent_class}>
                      <Typography variant="caption" className={classes.project_label}>
                        {label}
                      </Typography>
                      <Typography variant="caption" className={classes.project_value}>
                        {value}
                      </Typography>
                    </Box>
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.app_bar_right}>
              <IconButton onClick={this.handleChatOptionClick}>
                <MoreVertIcon />
              </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.chatOptionEl}
                    keepMounted
                    open={Boolean(this.state.chatOptionEl)}
                    onClose={this.handleChatOptionClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={this.onRefresh}>Refresh</MenuItem>
                  </Menu>
            </Box>
          </AppBar>
          )
        }
       
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {/* @ts-ignore */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToogle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {this.renderConversationContent(getConversationLoading, conversationProps)}
             
            </Drawer>
          </Hidden>
          {/* @ts-ignore */}
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: `${classes.drawerPaper}`,
              }}
              variant="permanent"
              open
            >
               {this.renderConversationContent(getConversationLoading, conversationProps)}
            </Drawer>
          </Hidden>
        </nav>
        <main className={`${classes.content} custom_scrollbar`}>
          {
            !selectedConversation ? <Box className={classes.center_align}>
              <Typography>No Conversation Selected</Typography>
            </Box>:(
               <ChatMainContent
                  messageList={messageList}
                  handleSetRepliedMessage={this.handleSetRepliedMessage}
                  handleChangeDeleteMessageModal={
                    this.handleChangeDeleteMessageModal
                  }
                  totalData={this.state.messagePagination.total_data}
                  currentUserId={userId?.toString() || ""}
                  getMessageLoading={this.state.getMessageLoading}
                  onLoadMore={this.onLoadMore}
                  autoScroll={this.state.autoScroll}
                  handleEditMessageOpen={this.handleOpenEditMessage}
                  sponsorUser={isLoggedinUserSponsor}
                />
            )
          }
         
        </main>
        {
          selectedConversation && (
            <div className={classes.chat_input}>
            <ChatInput
              handleSendMessage={this.handleSendMessage}
              repliedMessage={this.state.repliedMessage}
              handleSetRepliedMessage={this.handleSetRepliedMessage}
              color={color}
              selectedConversation={selectedConversation}
            />
          </div>
          )
        }
       
      </Box>
      <ActionModal
        key="delete_message_modal"
        message={deleteMessage?.attributes?.message || ""}
        title="Are you sure you want to delete this message?"
        actionHandler={this.handleDeleteMessage}
        open={deleteMessageModal}
        setOpen={this.handleDeleteMessageModalOpen}
        actionLoading={false}
        positiveButtonText="Yes"
        negativeButtonText="No"
      />
      <EditChatModal
        color={color}
        editLoading={this.state.editMessageLoading}
        editMessage={this.state.editMessage}
        handleRemoveEditMessageAttachment={this.handleEditMessageFileDelete}
        onEdit={this.handleEditMessage}
        open={this.state.openEdit}
        setOpen={this.handleCloseEditMessage}
        selectedConversation={selectedConversation}
      />
       <br/>
      <br/>
      </Container>
     
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const  styles = (theme: Theme) =>
createStyles({
  chat_main_container: {
    background: "white",
    marginTop: "30px",
    width: "100%",
    height: "80vh",
    border: "1px solid #e4e6e8",
    borderRadius: "15px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media(max-width: 599px)": {
      height: "auto",
    },
  },
  center_align:{
    height:"100%",
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
  },

  appBar: {
    background: "transparent",
    height: `${headerHeight}px`,
    borderBottom: "1px solid #e4e6e8",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "none",
    flexDirection: "row",
    color: "black",
    zIndex:80,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  chat_input: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  app_bar_left: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexDirection: "row",
  },
  app_bar_user_container: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexDirection: "row",
  },
  app_bar_user_img_container: {
    height: "50px",
    width: "50px",
    position: "relative",
    flex: "0 0 50px"
  },
  app_bar_user_img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  app_bar_user_active: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    position: "absolute",
    right: "0px",
    bottom: "0px",
    background: "green",
  },
  app_bar_user_details: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    marginTop: "7px"
  },
  app_bar_user_name: {
    fontSize: "15px",
    fontWeight: "bolder",
  },
  app_bar_chat_description: {
    fontSize: "12px",
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    position: "absolute",
    background: "#F9F5FC",
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
    zIndex:90,
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: `${drawerWidth}px`,
    },
    height: "100%",
    overflowY: "scroll",
  },
  parent_class: {
    display: 'flex'
  },
  project_label: {
    fontSize: '0.9rem',
  },
  project_value:{
    marginLeft: 5,
    fontSize: '0.9rem',
    fontWeight: 'bolder',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    alignSelf: 'baseline',
    flex: 1,
  }
});
export const ChatPage= withStyles(styles, { withTheme: true })(Chat);
// Customizable Area End
