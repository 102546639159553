import React from "react";
//@ts-nocheck

import {
    // Customizable Area Start
    Grid,
    Box,
    Typography,
    Card,
    CardMedia,
    Button,
    // Customizable Area End
} from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import StarRatings from 'react-star-ratings';
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";




// Customizable Area Start
import {images} from "./assets"
// Customizable Area End

import ReviewsController, {
    Props,
} from "./ReviewsController.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";

export default class Reviews extends ReviewsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        // Customizable Area Start
        const { ratingsLoading, favCards, nextPage } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Sponserheader navigation={this.props.navigation} id={""} route={""} />
                <div style={webStyle.container}>
                    <Box style={webStyle.header}>
                        <Button
                            data-testid="backBtn"
                            onClick={() => window.history.back()}
                            style={webStyle.backButton}
                            variant="contained"
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            Back
                        </Button>
                        <Typography style={webStyle.headingText}>Reviews </Typography>
                    </Box>
                    {favCards.map((rating: any, id: number) => {
                        const lancer = rating.attributes.reviewer_profile
                        return (
                            <Card
                                key={id}
                                style={webStyle.cardStyleCard}

                            >
                                <Grid container style={{gap: "10px"}}>
                                    <Grid item xs="auto" sm={2}>
                                        <Box style={webStyle.profileAvatar}>
                                            <CardMedia
                                                style={webStyle.avatarActive}
                                                component="img"
                                                alt="profile"
                                                image={lancer.photo?.url?lancer.photo?.url:images} />
                                        </Box>

                                    </Grid>
                                    <Grid item xs="auto" sm={10} >
                                        <Box style={webStyle.subgrid}>
                                            <Box >
                                                <Typography style={{ fontSize: "16px", color: "#000" }}>{lancer.reviewer_name}</Typography>
                                                <Box style={{ display: "flex", alignItems: "center", justifyContent: "left", marginTop: "5px" }}>
                                                    <Typography style={{
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                        color: "#8083A3",
                                                    }}>   { } </Typography>
                                                    <LocationOnOutlinedIcon
                                                        style={{ fontSize: "1rem", color: "rgba(0, 0, 0, 0.87)!important" }}
                                                    />
                                                    <Typography style={{
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                        color: "#8083A3",
                                                    }}>{lancer.city},{lancer.country}</Typography>
                                                </Box>

                                                <div style={{ display: "flex", marginTop: "5px" }}>
                                                    <Box p={0.2}>
                                                        <StarRatings
                                                            rating={lancer.avg_rating ? lancer.avg_rating : 0}
                                                            starDimension="12px"
                                                            starSpacing="2px"
                                                            starRatedColor="orange"
                                                            starEmptyColor="gray"
                                                            starHoverColor="orange"
                                                            data-test-id="ratingIcons" />
                                                    </Box>
                                                    <Typography
                                                        color="textSecondary"
                                                        className="freelancer-search-ratings"
                                                    >

                                                        <span style={{ fontSize: "12px", color: "#8083A3" }}>
                                                            {`${lancer.rating}/5 (${lancer.no_of_reviews} reviews)`}
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </Box>
                                            <Box style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                gap: "10px"
                                            }}>

                                            </Box>
                                        </Box>
                                        <Box style={webStyle.descriptiontext} width={"100%"}>


                                            <Typography style={webStyle.projectNameText}>
                                                {rating.attributes.project_details.project_name}
                                            </Typography>

                                            <Box style={webStyle.ratingMainBox}>
                                                <StarRatings
                                                    rating={rating.attributes.score}
                                                    starDimension="15px"
                                                    starSpacing="2px"
                                                    starRatedColor="orange"
                                                    starEmptyColor="gray"
                                                    starHoverColor="orange"
                                                    data-test-id="ratingIcons" />

                                            </Box>
                                            <Box paddingY={1.5}>
                                                <Typography style={webStyle.pastProjectDescription}>
                                                    {rating.attributes.description}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Grid>
                                </Grid>

                            </Card>
                        )
                    })}
                    <Box style={webStyle.loadMoreBtns}>
                        {ratingsLoading && (
                            <Box>
                                <CircularProgress />
                            </Box>
                        )}

                        {nextPage && !ratingsLoading && (
                            <Button
                                style={{ width: '137px', borderRadius: '30px', backgroundColor: '#F2F2F2', textTransform: 'capitalize' }}
                                variant="contained"
                                endIcon={<ChevronRightSharpIcon />}
                                onClick={this.handleProjectShowMore}
                                hidden={ratingsLoading}
                                data-test-id='showMore'
                            >
                                Show More
                            </Button>
                        )
                        }
                    </Box>
                </div>
                <Sponserfooter navigation={undefined} id={""} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    cardStyleCard: {
        marginBottom: "24px",
        borderRadius: "10px",
        border: "1px solid #E4E6E8",
        boxShadow: 'none',
        padding: "25px",
    },
    descriptiontext: {
        margin: "20px 20px 0px 0px"
    },

    profileAvatar: {
        position: "relative",
        width: "80px",
        height: "80px",
        // margin: "24px 0px 24px 30px",
    } as const,
    avatarActive: {
        height: "80px",
        width: "80px !important",
        borderRadius: "50%",
        objectFit: "cover",

        position: "relative",

    } as const,
    subgrid: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%'
    },
    container: {
        padding: '2vw 6vw',
    },
    projectNameText: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 700
    } as const,
    ratingMainBox: {
        marginTop: "8px",
        display: "flex",
        gap: "10px"
    },
    pastProjectDescription: {
        color: "#222",
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: 400
    } as const,
    loadMoreBtns: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: "300px",
        justifyContent: "center",
        margin: "auto",
    } as const,
    favText: {
        marginTop: "5px",
        fontWeight: "bold",
        fontSize: "28px",
        color: "#222"
    } as const,
    favBox: {
        // textAlign: "left",
    } as const,
    header: {
        display: "flex",
        alignItems: "center",
        gap: "23px",
        marginBottom: "24px",
    },
    backButton: {
        boxShadow: "none",
        borderRadius: "20px",
        padding: "10px 20px",
        textTransform: "capitalize",
    } as const,
    headingText: {
        fontWeight: "bolder",
        fontSize: "28px",
        color: "black",
    } as const,


};


// Customizable Area End
