
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

  // Customizable Area Start
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IProjectData } from "../../dashboard/src/types";
import { getStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

import {toast} from 'react-toastify'
import { IProfile } from "../../rolesandpermissions/src/Roles.types";

export interface ConversionParams{
  currency: string;
  currency_symbol: string;
  amount: number;
  first_milestone_cost_with_tax: number;
}
interface CurrencyConversion {
  sponsor: ConversionParams;
  freelancer: ConversionParams;
}
 interface ITaxInfo {
    name: string;
    sponsor_country: string;
    freelancer_country: string;
    cost: string;
    total_cost: number;
    lancer_currency:ConversionParams
  }
  
  interface IMilestoneCost {
    MilestoneCost: number;
    TaxForMilestone: ITaxInfo[];
    totalMilestonCostWithTax: number;
    CurrencyConversion:CurrencyConversion;
  }
  // Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  classes?:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
 
  // Customizable Area Start
  project:IProjectData | null;
  getProjectLoading:boolean;
  milestoneCost:IMilestoneCost | null;
  payMilestoneLoading:boolean;
  getMilestoneCostLoading:boolean;
  currentUser:IProfile|null;
  // Customizable Area End

}

interface SS { }

export default class FreelancerBrowseProjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProjectDetailsApiCallId:string="";
  getMilestoneCostApiCallId:string="";
  payMilestoneApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area Start
      getName(MessageEnum.LoginUserMessage),
      // Customizable Area End
    ]);

    this.state = {
        // Customizable Area Start
        project:null,
        getProjectLoading:false,
        milestoneCost:null,
        payMilestoneLoading:false,
        getMilestoneCostLoading:false,
        currentUser:null,
        // Customizable Area End
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const currentUser = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      if(currentUser){
        this.handleLoginUserResponse(currentUser);
      }
    }
    // Customizable Area End
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    // Customizable Area Start
      this.handleApiResponse(apiRequestCallId,responseJson);
    // Customizable Area End

    }
  }
// Customizable Area Start
handleLoginUserResponse=(currentUser:any)=>{
  if(currentUser?.id){
    this.setState({ currentUser: currentUser }, ()=> {
      this.getProjectDetails();
      this.getMilestoneCost();
    });
  }
}
  handleApiResponse=(apiRequestCallId:string,responseJson:any)=>{
        if(apiRequestCallId===this.getProjectDetailsApiCallId){
           this.handleProjectApiResponse(responseJson);
        }else if(apiRequestCallId===this.getMilestoneCostApiCallId){
           this.handleMilestoneCostResponse(responseJson);
        }else if(apiRequestCallId===this.payMilestoneApiCallId){
           this.handlePayMilestoneResponse(responseJson);
        }
  }
  handlePayMilestoneResponse=async(responseJson:any)=>{
    this.setState({payMilestoneLoading:false})

    if(responseJson?.payment_link){
      await setStorageData("approveMilestone","yes");
      const link = document.createElement('a');
      link.href = responseJson?.payment_link;
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }else{
    toast.error("Something wen't wrong, Please try again.")
   }
  }

  handleMilestoneCostResponse=(responseJson:any)=>{
    if(responseJson?.response){
      this.setState({
        milestoneCost:responseJson.response,
        getMilestoneCostLoading:false,
      })
    }else{
      this.setState({getMilestoneCostLoading:false})
      toast.error("Something wen't wrong!!")
    }
  }

  handleProjectApiResponse=(responseJson:any)=>{
    if(responseJson?.data){
      this.setState({
        project:responseJson.data,
        getProjectLoading:false
      })
   }else{
     this.setState({
        getProjectLoading:false
     })
     toast.error("No Project Found!!!")
   }
  }

 async componentDidMount(){
      this.sendGetLoggedInUserMessage();
  }


  sendGetLoggedInUserMessage=()=>{
    const loginUserMessage: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(loginUserMessage);
  }
  

  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token
    };
    return header;
  }
  payMilestoneCost=async()=>{
     this.setState({payMilestoneLoading:true})
     const header=await this.getHeader();
     const milestoneId=this.props.navigation.getParam("milestoneId");
     const requestMessage=CreateRestApiMessage({
         header:header,
         apiUrl:`${configJSON.payMilestoneApiUrl}?milestone_id=${milestoneId}`,
         body:null,
         method:"POST"
     })
     this.payMilestoneApiCallId=requestMessage.messageId;
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getMilestoneCost=async()=>{
    this.setState({getMilestoneCostLoading:true})
    const header=await this.getHeader();
    const milestoneId=this.props.navigation.getParam("milestoneId");
    const requestMessage=CreateRestApiMessage({
        header:header,
        apiUrl:`${configJSON.getMilestoneCostApiUrl}?milestone_id=${milestoneId}`,
        body:null,
        method:"GET"
    })

    this.getMilestoneCostApiCallId=requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProjectDetails=async()=>{
    this.setState({
         getProjectLoading:true,
    })
    const header=await this.getHeader();
    const projectId=this.props.navigation.getParam("projectId");
    const requestMessage=CreateRestApiMessage({
        header:header,
        apiUrl:`${configJSON.getSingleProjectApiUrl}/${projectId}`,
        body:null,
        method:"GET"
    })

    this.getProjectDetailsApiCallId=requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMilestoneDetails=()=>{

  }
  onGoBack=()=>{
    this.props.navigation?.goBack();
  }
  // Customizable Area End
}
