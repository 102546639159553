import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  Label,
} from "recharts";
import CustomTooltip, {
  COLOR_HIGH_CONTRAST,
  COLOR_LOW_CONTRAST,
} from "./CustomTooltip.web"

interface Props {
  data?: any[];
  isAllProjects?: boolean;
}
  
const CustomBarChart = ({ data, isAllProjects = false }: Props) => {
  const calculateTicks = () => {
    const maxValue = Math.max(
      ...(data?.map(item => Math.max(
        Math.ceil(item.exactCompletedDate || 0), 
        Math.ceil(item.expectedCompletedDate || 0)
      )) || [0])
    );

    return Array.from({ length: maxValue + 1 }, (_, i) => i);
  };
  return (
     <ResponsiveContainer width="100%" height={400}>
     <BarChart 
    data={data}
    margin={{
   
      top: 20,
      right: 30,
      bottom: 50, 
     }}
    >
 <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          style={{ marginTop: "18px" }}
          interval={0} 
          tick={{ fontSize: 12 }}
          angle={-45} 
          textAnchor="end" 
          height={60} 
          dy={10} 
          tickFormatter={(value) => (value.length > 10 ? value.slice(0, 10) + '...' : value)}
          >
          <Label
              value={isAllProjects ? "All Projects" :"Project Milestones"}
              offset={-40}
              position="insideBottom"
              style={{
                fill: "#000",
                fontWeight:500
            }}
            />
          </XAxis>
          <YAxis tickLine={false} axisLine={false} domain={[0, 'auto']} ticks={calculateTicks()}>
          <Label
                value="Days"
                offset={10}
                position="insideLeft"
                angle={-90}
                style={{ fill: "#000", fontWeight:500}}
              />
          </YAxis>
            <Tooltip wrapperStyle={{ 
              backgroundColor: '#f5f5f5',
               border: '1px solid #d5d5d5',
                borderRadius: 6, 
                padding:"10px",
                 lineHeight:'15px',
                }} 
                 content={<CustomTooltip />} />
          <Bar
          dataKey="exactCompletedDate"
          barSize={10}
             fill={COLOR_HIGH_CONTRAST}
          radius={15}
        />
        <Bar dataKey="expectedCompletedDate" 
        barSize={10} 
        fill={COLOR_LOW_CONTRAST}
        radius={15} />
      </BarChart>
      </ResponsiveContainer>
  
  );
};

export default CustomBarChart;

