// Customizable Area Start
import React from "react";
import ProjectBidController, {
  Props,
} from "./ProjectBidController.web";
import FreelancerHeader from "../FreelancerHeader.web";
import {Container,Typography , Box} from '@material-ui/core'
import ProjectCard from "../../../../components/src/ProjectCard.web";
import "../../assets/css/MyBid.css"
import AboutSponsor from '../../../../components/src/my-bid/AboutSponsor.web'
import FullPageBackdropLoader from "../../../../components/src/utill/FullPageBackdropLoader.web";
import { leftArrow} from "../assets";

class BidProjectDetails extends ProjectBidController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {project,projectLoading}=this.state;
    return (
     <>
     
        <FullPageBackdropLoader open={projectLoading}/>
        {localStorage.getItem("loginSuccessToken")&&<FreelancerHeader navigation={this.props.navigation}/>}
        <Container>
            <br/>
            <br/>
            <div style={{display:"flex" , gap:"10px", flexWrap: "wrap"}}>
            <Box
                data-testid="Backbtntestid"
                className="Negotiate_Bid newBid_container"
                style={{marginBottom:"10px"}}
                onClick={this.handleGoBackButton}
                >
                <img src={leftArrow} alt="image" style={{ paddingRight: "10px" }} />
                <span>Back</span>
            </Box>
            <h5 className=" newBid_container" style={{marginRight:"10px"}}>
            Project Details
            </h5> 
            </div>

            {
              project !==null? <ProjectCard card={project} onSubmitBidClick={this.navigateToBidProject} fav={this.addFavouriteProject}/>:<Typography>No Project Found</Typography>
            }
            
           
            <br/>
            <AboutSponsor 
               name={project?.attributes?.sponsor_profile?.full_name as string}
               city={project?.attributes?.sponsor_profile?.city}
               country={project?.attributes?.sponsor_profile?.country}
               rating={project?.attributes?.sponsor_profile?.rating || 0}
               totalRatingCount={project?.attributes?.sponsor_profile?.total_rating || 0}
               id = {project?.attributes?.sponsor_profile?.id}
               profileImage={project?.attributes?.profile_image}
               />
              
        </Container>
     </>
    );
  }
}

export default BidProjectDetails;

// Customizable Area End
