import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import { lancerlogo, searchicon } from "./assets";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Formik, Field, Form, FieldArray } from "formik";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from 'react-select';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End
import ProfilesetupController, { Props } from "./ProfilesetupController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import StudentForm from "../../../components/src/profile-setup/StudentForm.web";
import SponsorForm from "../../../components/src/profile-setup/SponsorForm.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";
import CreateUniversityModal from "../../../components/src/utill/CreateUniversityModal.web";
export default class Studentboth extends ProfilesetupController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { skill_ids, hobby_ids, uploaded_documents, student_id } = this.state;

    return (
      // Customizable Area Start
      <div className="main-wrapper student_both_freelancer">
        <ProfileSetupHeader
          email={this.state.loggedInUserEmail}
          onLogout={this.sendLogoutMessage}
        />
        <div className="site-wrapper student_both_freelancer">
          <div className="setup-account-block student_both_freelancer">
            <div className="inner-block student_both_freelancer">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper
              activeBasicDetails={true}
              activeProfileSetup={true}
            />
          </div>

          <div className="choose-profile-block">
            <div className="profile-heading">
              <h6>Fill the details for profile(s) selected by you</h6>
            </div>
            <div className="profile-tab-wrapper">
              <div className="tab-panel-block">
                <Tabs
                  data-testid="tabs"
                  value={this.state.tab}
                  centered
                  onChange={(event: any, value: boolean) =>
                    this.handleChange(event, value)
                  }
                >
                  <Tab label="Freelancer Profile (Student)" value={true} />
                  <Tab label="Sponsor" value={false} />
                </Tabs>
                <div hidden={!this.state.tab}>
                  <div className="form-block">
                    <StudentForm
                        data-testid="studentForm"
                        submitHandler={this.handleStudentFormSubmitBoth}
                        handleStudentIdChange={ this.handleCollegeIDUploadChange}
                        cancelHandler={this.handleGoBack}
                        student_id={student_id}
                        freelancingExperienceList={this.state.freelancingExperienceList}
                        uploaded_documents={uploaded_documents}
                        handleUploadDocumentChange={this.handleFileChange}
                        countryList={this.state.countryDataList}
                        submitLoading={this.state.submitLoading}
                        countryLoading={this.state.getCountryLoading}
                        cityList={this.state.cityDataList}
                        getCityList={this.getCityDataList}
                        cityLoading={this.state.getCityLoading}
                        skills={this.state.skillList}
                        categories={this.state.categoriesList}
                        educationList={this.state.educationList}
                        fieldOfStudyList={this.state.fieldOfStudyList}
                        universityList={this.state.universityList}
                        getStateList={this.getStateDataList}
                        getStateLoading={this.state.getStateLoading}
                        stateList={this.state.stateDataList}
                        handleOpenUniversityModal={this.handleCreateUniversityModalOpen}
                        newlyCreatedUniversity={this.state.chosenCreatedUniversity}
                    />
                  </div>
                </div>
                <div hidden={this.state.tab}>
                  <div className="form-block">
                    <SponsorForm
                        submitHandler={(values:any,{setErrors}:any)=>{
                          localStorage.setItem("sponsorForm",JSON.stringify(values))
                          this.checkSponsorProfile({values,setErrors},"studentForm")
                       }}
                        cancelHandler={this.handleGoBack}
                        categories={this.state.categoriesList}
                        submitLoading={this.state.submitLoading}
                        industryList={this.state.industryList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateUniversityModal
            open={this.state.openCreateUniversityModal}
            setOpen={this.handleCreateUniversityModalOpen}
            createUniversityLoading={this.state.createUniversityLoading}
            onCreateUniversity={this.createNewUniverisy}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
