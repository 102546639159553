
import React, {useState,useEffect} from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
  ListItemText,
  Checkbox,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface Props {
  label: string;
  values: string[];
  onChange: any;
  selectedOptions:any[];
  disabled?: boolean;
  disabledPlaceholder?: string;
}

const MultiSelect: React.FC<Props> = ({ label, values, onChange,selectedOptions, disabled = !values?.length, disabledPlaceholder }) => {
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    if (!disabled) {
      setIsClicked(false);
    }
  }, [disabled]);
  const handleDisabledClick = () => {
    if (disabled) {
      setIsClicked(true);
    }
  };
  return (
    <>
      <InputLabel style={{ marginBottom: "0px" }}>{label}</InputLabel>
      <FormControl style={{ display: "block" }} disabled={disabled}>
        <Select
          fullWidth
          displayEmpty
          multiple={true}
          value={selectedOptions}
          onChange={(selectedOptions) => onChange(selectedOptions)}
          input={<Input />}
          disabled={disabled}
          onClick={handleDisabledClick}
          IconComponent={() => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "8px",
                transform: "translateY(-50%)",
                zIndex: -1,
                color:"#8083A3"
              }}
            >
              <ExpandMoreIcon />
            </div>
          )}
          renderValue={(selected) => {
            if (disabled) {
              return (
                <Typography style={{fontSize:"14px"}}>
                  {isClicked && disabledPlaceholder ? disabledPlaceholder : `Select ${label}`}
                </Typography>
              );
            }
            if ((selected as string[]).length === 0) {
              return <Typography style={{fontWeight:"bold",fontSize:"14px"}}>Select {label}</Typography>;
            }
            const selectedData = Array.isArray(selected)
              ? (selected as string[])
              : [selected as string];
            return Array.isArray(selectedData)
              ? selectedData.join(", ")
              : selectedData;
          }}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
            anchorOrigin: {
              horizontal: "left",
              vertical: "bottom",
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: 300,
                width: 250,
              },
            },
          }}
        >
          {values?.map((option) => (
            <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={selectedOptions.includes(option)}
                  />
                  <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MultiSelect;
