import React from "react";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

interface Props {
  myMessage: boolean;
  onReply: () => void;
  onDelete: () => void;
  onEdit: () => void;
  isDeleted?: boolean;
}

const ChatMessageAction: React.FC<Props> = ({
  myMessage,
  onReply,
  onDelete,
  onEdit,
  isDeleted = false
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isDeleted && !myMessage) {
    return null;
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {myMessage ? (
          !isDeleted ? (
          <div>
            <MenuItem onClick={()=>{
               handleClose()
               onEdit()
            }}>Edit</MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                onDelete();
              }}
              style={{ color: "red" }}
            >
              Delete
            </MenuItem>
          </div>
          ) : null
        ) : (
          !isDeleted && (
            <MenuItem
              onClick={() => {
                handleClose();
                onReply();
              }}
            >
              Reply
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default ChatMessageAction;
