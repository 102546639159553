import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles} from '@material-ui/core';
import { getStorageData } from "../../../framework/src/Utilities";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IProjectData } from "../../dashboard/src/types";
import {toast} from 'react-toastify'
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
  navigation:any;
  id:string;

}
export interface S {
  // Customizable Area Start
  getProjectLoading:boolean;
  projectInfo:IProjectData|null;
  certificationCost:number;
  confirmPayModal:boolean;
  paymentLoading:boolean;
  currentUser:IProfile|null;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class FreelancerCertificationtrackingControllers extends BlockComponent<
  Props, S, SS
> {

    // Customizable Area Start
    getProjectInfoApiCallId:string="";
    getCertificationCostApiCallId:string="";
    paymentCertificationApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

        this.state = {
            // Customizable Area Start
            projectInfo:null,
            getProjectLoading:false,
            certificationCost:0,
            confirmPayModal:false,
            paymentLoading:false,
            currentUser:null,
            // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
       this.handleCurrentUserResponse(user);
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(apiRequestCallId===this.getProjectInfoApiCallId){
           this.handleGetProjectInfoResponse(responseJson);
        }else if(apiRequestCallId===this.getCertificationCostApiCallId){
          this.handleGetCertificationCostPayload(responseJson);
        }else if(apiRequestCallId===this.paymentCertificationApiCallId){
          this.handlePaymentApiResponse(responseJson);
        }
      }
    // Customizable Area End
  }
  handleCurrentUserResponse=(user:any)=>{
    if(user?.id){
      this.setState({ currentUser: user });
    }
   
  }
  handlePaymentApiResponse=(responseJson:any)=>{
    this.setState({
      paymentLoading:false,
      confirmPayModal:false,
    })
    if(responseJson?.auto_payment){
      toast.success(responseJson?.message);
      this.getProjectDetails();
      return;
    }else if(responseJson?.payment_link){
      if(responseJson?.payment_link){
        const link = document.createElement('a');
        link.href = responseJson?.payment_link;
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.location.href=responseJson?.payment_link;
      }
    }else{
      this.setState({paymentLoading:false})
      toast.error("Oops! something went wrong")
    }
  }
  handleGetProjectInfoResponse=(responseJson:any)=>{
      this.setState({getProjectLoading:false});
      if(responseJson?.data?.id){
        this.setState({
          projectInfo:responseJson?.data
        })
      }else{
          toast.error("Project not found!")
      }
  }
  handleGetCertificationCostPayload=(responseJson:any)=>{
    if(responseJson?.data?.id){
      this.setState({
        certificationCost:responseJson?.data?.price
      })   
    }
  }
  async componentDidMount() {
    // Customizable Area Start
    this.sendGetCurrentUserMessage();
    this.getProjectDetails();
    this.getCertificationCost();
    // Customizable Area End
  }


  sendGetCurrentUserMessage=()=>{
    const currentUserMessage: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(currentUserMessage);
  }
  

  getProjectDetails = async() => {
    const projectId= this.props.navigation.getParam("projectid")
    this.setState({getProjectLoading:true})
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.contentTypeApi,
    };
    const requestURL=configJSON.getDataApiCallIdUrl + `${projectId}`;
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl:requestURL,
      body: null,
      method: "GET",
    });
  
    this.getProjectInfoApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  getCertificationCost = async() => {
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.contentTypeApi,
    };
    const requestURL=configJSON.getCertificationCostApiUrl;
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl:requestURL,
      body: null,
      method: "GET",
    });
  
    this.getCertificationCostApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  paymentForCertificate = async() => {
    this.setState({paymentLoading:true})
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.contentTypeApi,
    };
    const requestURL=`${configJSON.paymentCertificationApiUrl}?project_id=${this.state.projectInfo?.id}`;
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl:requestURL,
      body: null,
      method: "POST",
    });
    this.paymentCertificationApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  handleOpenConfirmModal=(open:boolean)=>{
    this.setState({confirmPayModal:open})
  }
  handlePaymentForConfirmation=()=>{
    this.setState({
      confirmPayModal:true
    })
  }
  goBack = async() => {
    this.props.navigation.goBack();
  }
  OnRequest=async()=>{
    this.props.navigation.navigate("FreelancerRequestForCorrection",{projectid:this.state.projectInfo?.attributes?.id})
  }
  onDownloadCertificate=async()=>{
    const {projectInfo}=this.state;
    const certificate=projectInfo?.attributes.certificate;
    if(!projectInfo || !certificate?.url){
      toast.error("Certificate not found!")
      return;
    }
    const response = await fetch(certificate.url);
    const blob = await response.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = certificate.name;
    link.click();
    window.URL.revokeObjectURL(fileUrl);
  }
  // Customizable Area End
}

